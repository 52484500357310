import { push } from 'connected-react-router';

import { callApi } from './commons.actions';
import { loginApi, oauthLoginApi, checkAuthApi } from '../api';

// actions
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_COMPLETE = 'USER_LOGIN_COMPLETE';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_HIDE_MESSAGE = 'USER_LOGIN_HIDE_MESSAGE';
export const USER_UPDATE_USER_INFO = 'USER_UPDATE_USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_CHECK_AUTH = 'USER_CHECK_AUTH';
export const USER_CHECK_AUTH_COMPLETE = 'USER_CHECK_AUTH_COMPLETE';
export const USER_CHECK_AUTH_ERROR = 'USER_CHECK_AUTH_ERROR';

export const USER_OAUTH_LOGIN = 'USER_OAUTH_LOGIN';
export const USER_OAUTH_LOGIN_ERROR = 'USER_OAUTH_LOGIN_ERROR';

export const userLoginHideMessage = () => ({ type: USER_LOGIN_HIDE_MESSAGE });
export const userUpdateUserInfo = data => ({
  type: USER_UPDATE_USER_INFO,
  data,
});

export const userLogout = () => ({ type: USER_LOGOUT });

export const userLogin = callApi({
  apiCall: p => loginApi(p.username, p.password),
  startAction: USER_LOGIN,
  successAction: USER_LOGIN_COMPLETE,
  errorAction: USER_LOGIN_ERROR,
  processError: (e, dispatch) =>
    setTimeout(() => dispatch(userLoginHideMessage()), 5000),
});

export const userOAuthLogin = callApi({
  apiCall: (id_token, access_token) => oauthLoginApi(id_token, access_token),
  startAction: USER_OAUTH_LOGIN,
  successAction: USER_LOGIN_COMPLETE,
  errorAction: USER_OAUTH_LOGIN_ERROR,
  processError: (e, dispatch) => {
    dispatch(push('/login'));
    setTimeout(() => dispatch(userLoginHideMessage()), 5000);
  },
});

export const checkUserAction = callApi({
  apiCall: p => checkAuthApi(),
  startAction: USER_CHECK_AUTH,
  successAction: USER_CHECK_AUTH_COMPLETE,
  errorAction: USER_CHECK_AUTH_ERROR,
  processError: (e, dispatch) => dispatch(userLogout()),
});
