/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  privacyListLoadAction,
  privacyActivateAction,
  privacyErrorAction,
  privacyDeleteAction,
  privacyCreateAction,
} from '../actions';

import l from '../libs/localization';
import Layout from '../layouts/Layout';
import Loader from '../components/Loader';
import PolicyTable from '../components/privacy/PolicyTable';
import CreatePolicyForm from '../components/privacy/CreatePolicyForm';

const PrivacyPage = ({
  data,
  loading,
  privacyImportList,
  message,
  activate,
  deletePrivacy,
  create,
}) => {
  const [formVisibility, setFormVisibility] = useState(false);
  const toggleFormVisibility = override => {
    // override area
    if (override === true || override === false) {
      setFormVisibility(override);
      return;
    }
    // actual toggle
    setFormVisibility(!formVisibility);
  };
  const toggleCreateButton = () => {
    toggleFormVisibility(true);
  };
  useEffect(() => {
    privacyImportList();
  }, [privacyImportList]);
  useEffect(() => {
    toggleFormVisibility(false);
  }, [data]);
  return (
    <Layout title={l('PRIVACY.PAGE_TITLE')}>
      {message && <span className="w-100 c-black">{message}</span>}
      <div className="notifications-container bg-white">
        <div className="privacy-menu">
          <p className="intro">{l('PRIVACY.INTRO')}</p>
          <button
            type="button"
            className="btn btn-success noti-create-button noti-menu-button"
            onClick={toggleCreateButton}
          >
            <span className="glyphicon glyphicon-plus" />
            <b className="margin-left-10">{l('NOTIFICATION.CREATE')}</b>
          </button>
        </div>
        <div className="absolute-privacy-table&form-container">
          <CreatePolicyForm
            closingCB={() => {
              toggleFormVisibility(false);
            }}
            onCreate={create}
            visible={formVisibility}
          />
          <PolicyTable
            data={data}
            visible={!formVisibility}
            policyActivate={activate}
            policyDelete={deletePrivacy}
          />
        </div>
      </div>
      <Loader visible={loading} />
    </Layout>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  message: PropTypes.string,
  privacyImportList: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  deletePrivacy: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
};

PrivacyPage.defaultProps = {
  data: [],
  loading: true,
  message: null,
};

const mapStateToProps = state => ({
  ...state.privacy,
});

export default connect(
  mapStateToProps,
  {
    onError: e => privacyErrorAction(e),
    privacyImportList: privacyListLoadAction,
    activate: privacyActivateAction,
    deletePrivacy: privacyDeleteAction,
    create: privacyCreateAction,
  }
)(PrivacyPage);
