import { apiUrl, getConfig } from '../config';

/**
 *
 * @param url
 */
const parseUrl = url => {
  if (url.indexOf('http') === 0) return url;
  return `${apiUrl}${url}`;
};

export const encodeParameters = (parameters, url = '') => {
  if (!parameters) return url;
  let prefix = '';

  if (url && url.length) {
    prefix += url.indexOf('?') < 0 ? '?' : '';
  }

  let stringParams = '';

  Object.keys(parameters)
    .filter(k => k && parameters[k] !== null && parameters[k] !== undefined)
    .forEach(k => {
      let val = parameters[k];
      if (Array.isArray(val)) {
        if (!val.length) return;
        val = `::${val.join('::')}`;
      }

      stringParams += `&${k}=${encodeURIComponent(val)}`;
    });

  return `${url}${prefix}${stringParams}`;
};

export const encodeLocationParamaters = (parameters, url) => {
  if (!parameters) return url;
  return `${url}?&search=${parameters}`;
};

/**
 * GET ajax request with bearer token with fetch
 * @param url endpoint to call
 * @returns {Promise<Response>}
 */
const get = async (url, parameters = null, headers = null) => {
  let effectiveUrl = parseUrl(url);
  if (parameters) effectiveUrl = encodeParameters(parameters, effectiveUrl);
  const options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {},
  };

  const token = getConfig('token');
  if (token) options.headers.Authorization = `bearer ${token}`;

  options.headers = {
    ...options.headers,
    ...headers,
  };

  const response = await fetch(effectiveUrl, options);
  if (response.status === 401) {
    throw new Error('notAuthorized');
  }
  const result = await response.json();

  if (response.status === 500) {
    throw new Error(`api error:${result.message}`);
  }

  return result;
};

const callHttp = async (method, url, data = null, headers = null) => {
  const effectiveUrl = parseUrl(url);
  let postData = data;
  if (typeof postData === 'object') {
    postData = encodeParameters(postData);
  }

  const options = {
    method,
    mode: 'cors',
    cache: 'no-cache',
    body: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const token = getConfig('token');
  if (token) options.headers.Authorization = `bearer ${token}`;

  if (headers) {
    options.headers = {
      ...options.headers,
      ...headers,
    };
  }

  const response = await fetch(effectiveUrl, options);

  if (response.status === 401) {
    throw new Error('notAuthorized');
  }

  const result = await response.json();

  if (response.status === 500) {
    throw new Error(`api error: ${result.message}`);
  }

  return result;
};

const post = (url, data = null, headers = null) =>
  callHttp('POST', url, data, headers);

const deleteRequest = (url, data = null, headers = null) =>
  callHttp('DELETE', url, data, headers);

const postJson = (url, data = '', headers = {}) =>
  post(url, JSON.stringify(data), {
    'Content-Type': 'application/json; charset=utf-8',
    ...headers,
  });
const getRaw = async (url, parameters = null, headers = null) => {
  let effectiveUrl = parseUrl(url);
  if (parameters) effectiveUrl = encodeParameters(parameters, effectiveUrl);
  const options = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {},
  };

  const token = getConfig('token');
  if (token) options.headers.Authorization = `bearer ${token}`;

  options.headers = {
    ...options.headers,
    ...headers,
  };

  const response = await fetch(effectiveUrl, options);
  if (response.status === 401) {
    throw new Error('notAuthorized');
  }
  const result = await response;

  if (response.status === 500) {
    throw new Error(`api error:${result.message}`);
  }

  return result;
};
export const http = {
  get,
  getRaw,
  parseUrl,
  encodeParameters,
  post,
  postJson,
  delete: deleteRequest,
};
