import { http } from './commons.api';

// import log from '../libs/logger';

export const listCompressorsApi = async params => {
  const response = await http.get('/api/v1/compressors', params);
  localStorage.setItem('CompressorDATA', JSON.stringify(response));
  return response;
};

export const loadDataLayoutApi = async id => {
  const response = await http.get(`/api/v1/structure`);
  return response;
};

export const loadCompressorApi = async ({ id }) => {
  const response = await http.get(`/api/v1/compressors/${id}`);
  return response;
};
export const ListCompressorUserApi = async params => {
  const response = await http.get('/api/v1/userCompressor', params);
  localStorage.setItem('UserCompressorInfo', JSON.stringify(response));
  return response;
};
