import React from 'react';
import PropTypes from 'prop-types';

import l from '../../libs/localization';

import { recoverUserPasswordApi } from '../../api/users.api';

class User extends React.Component {
  constructor(props) {
    super(props);
    const { r } = this.props;
    const { brand } = this.props;
    const { defaultPartnumber } = this.props;
    this.state = {
      show: false,
      r,
      brand,
      defaultPartnumber,
    };
    if (r.language == null) {
      return (r.language = 'en');
    }

    this.tablerefs = {
      text: React.createRef(),
      plannedSendAt: React.createRef(),
    };
    this.toggleForm = this.toggleForm.bind(this);
    this.undo = this.undo.bind(this);
    this.saveUser = this.saveUser.bind(this);
  }

  componentWillReceiveProps(newprops) {
    console.log('newprops', newprops);
    if (newprops.r) {
      console.log('newprops.r', newprops.r);
      this.setState({ r: newprops.r });
    }
  }

  saveUser = (e, keepFormState = false) => {
    const { r } = this.state;
    const data = r;
    console.log('propes save', this.props);
    const { onSubmit, filters } = this.props;

    if (onSubmit) {
      onSubmit({ data, filters });
    }
    if (!keepFormState) {
      this.toggleForm();
    }
  };

  toggleActive() {
    if (window.confirm(l('USERS.CONFIRM.ACTIVATION'))) {
      this.setState(
        prevState => (
          { r: { ...prevState.r, active: !prevState.r.active } },
          { brand: { ...prevState.brand, active: !prevState.brand.active } },
          {
            defaultPartnumber: {
              ...prevState.defaultPartnumber,
              active: !prevState.defaultPartnumber.active,
            },
          }
        ),
        () => this.saveUser(null, true)
      );
    }
  }

  toggleForm = () => {
    this.setState(
      prevState => (
        console.log('prev satet', prevState), { show: !prevState.show }
      )
    );
  };

  undo() {
    const { r } = this.props;
    this.setState({ r });
    this.toggleForm();
  }

  render() {
    const { filters, userDelete } = this.props;
    const { show, r, brand, defaultPartnumber } = this.state;
    if (r.firstName !== null) {
      r.name = r.firstName;
    }

    return (
      <tr key={r.id} title={r.statusMessage} className={show ? 'bg-grey' : ''}>
        <td>
          <span
            className={r.active ? 'fa fa-user c-orange' : 'fa fa-user c-black'}
          />
        </td>
        <td className="user-text">{r.email}</td>

        <td>
          {show ? (
            <input
              type="text"
              className="table-input"
              name="name"
              placeholder={l('USERS.NAME')}
              value={r.name}
              onChange={e =>
                this.setState({ r: { ...r, name: e.target.value } })
              }
            />
          ) : (
            r.name
          )}
        </td>
        <td className="user-text">{r.lastName}</td>
        <td>
          {show ? (
            <select
              type="select"
              className="users-select"
              placeholder={l('USERS.TYPE')}
              name="type"
              value={r.type}
              onChange={e =>
                this.setState({ r: { ...r, type: e.target.value } })
              }
            >
              <option value="admin">{l(`USERS.ROLES.ADMIN`)}</option>
              <option value="app">{l(`USERS.ROLES.APP`)}</option>
              <option value="user">{l(`USERS.ROLES.USER`)}</option>
            </select>
          ) : (
            r.type
          )}
        </td>
        <td className="user-text">{r.language}</td>
        <td>{r.country}</td>
        <td>{brand}</td>
        <td>{defaultPartnumber}</td>

        <td>{l(new Date(r.lastLogin), 'MMMM Do YYYY, HH:mm')}</td>
        <td className="user-edit">
          <div className="d-flex j-c-c">
            {show ? (
              <>
                <button
                  type="button"
                  className=" btn-outline btn-user-action m-0"
                  onClick={this.saveUser}
                >
                  <span className="glyphicon glyphicon-save" />
                </button>
                <button
                  type="button"
                  className=" btn-outline btn-user-action m-0"
                  onClick={this.undo}
                >
                  <span className="glyphicon glyphicon-arrow-left" />
                </button>
              </>
            ) : (
              <button
                type="button"
                className=" btn-outline btn-user-action "
                onClick={this.toggleForm}
              >
                <span className="glyphicon glyphicon-edit" />
              </button>
            )}

            {r.type === 'admin' && (
              <button
                type="button"
                className=" btn-outline btn-user-action"
                onClick={() => {
                  if (window.confirm(l('USERS.CONFIRM.RECOVER'))) {
                    recoverUserPasswordApi({ email: r.email });
                  }
                }}
              >
                <span className="fa fa-key" />
              </button>
            )}
            {r.active ? (
              <button
                type="button"
                className=" btn-outline btn-user-action"
                onClick={this.toggleActive.bind(this, {
                  email: r.email,
                  filters,
                })}
              >
                <span className="glyphicon glyphicon-pause" />
              </button>
            ) : (
              <button
                type="button"
                className=" btn-outline btn-user-action"
                onClick={this.toggleActive.bind(this, {
                  email: r.email,
                  filters,
                })}
              >
                <span className="glyphicon glyphicon-play" />
              </button>
            )}
            {r.type === 'admin' && (
              <button
                type="button"
                className=" btn-outline btn-user-action"
                onClick={() => {
                  if (window.confirm(l('USERS.CONFIRM.DELETE'))) {
                    userDelete({ email: r.email, filters });
                  }
                }}
              >
                <span className="glyphicon glyphicon-remove" />
              </button>
            )}
          </div>
        </td>
      </tr>
    );
  }
}
User.propTypes = {
  r: PropTypes.shape({
    active: PropTypes.bool,
    name: PropTypes.string,
    email: PropTypes.string,
    lastLogin: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  filters: PropTypes.shape({
    active: PropTypes.bool,
    search: PropTypes.string,
    type: PropTypes.string,
  }),
  // brand: propTypes.string.isRequired,
  // defaultPartnumber: propTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userDelete: PropTypes.func.isRequired,
};

User.defaultProps = {
  filters: {},
};

export default User;
