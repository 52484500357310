import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ visible, full = true }) => {
  if (!visible) return null;

  const className = full ? 'full-loader' : 'loader';

  return (
    <div className={className}>
      <div className="lds-dual-ring">&nbsp;</div>
    </div>
  );
};

Loader.propTypes = {
  visible: PropTypes.bool,
};

Loader.defaultProps = {
  visible: true,
};

export default Loader;
