/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  versionListLoadAction,
  versionActivateAction,
  versionErrorAction,
  versionDeleteAction,
  versionCreateAction,
  versionEditAction,
} from '../actions/version.actions';

import l from '../libs/localization';
import Layout from '../layouts/Layout';
import Loader from '../components/Loader';
import VersionTable from '../components/version/VersionTable';
import CreateVersionForm from '../components/version/CreateVersionForm';

const VersionPage = ({
  data,
  loading,
  versionImportList,
  message,
  activate,
  deleteVersion,
  create,
  versionEdit,
}) => {
  const [formVisibility, setFormVisibility] = useState(false);
  const [tempVersion, setTempNotification] = useState({
    id: null,
    currentIOSAppVersion: null,
    currentAndroidAppVersion: null,
    androidPlayStoreURL: null,
    iOSAppStoreURL: null,
    shouldForceUpgrade: false,
  });
  const toggleFormVisibility = override => {
    // override area
    if (override === true || override === false) {
      setFormVisibility(override);
      return;
    }
    // actual toggle
    setFormVisibility(!formVisibility);
  };
  // const toggleCreateButton = () => {
  //   toggleFormVisibility(true);
  // };
  const toggleEditButton = notification => {
    // console.log("this is data:",notification)
    setTempNotification(notification);
    toggleFormVisibility(true);
  };

  useEffect(() => {
    versionImportList();
  }, [versionImportList]);
  useEffect(() => {
    toggleFormVisibility(false);
  }, [data]);
  return (
    <Layout title={l('VERSION.PAGE_TITLE')}>
      {message && <span className="w-100 c-black">{message}</span>}
      <div className="notifications-container bg-white">
        {/* <div className="privacy-menu">
            <p className="intro">{l('PRIVACY.INTRO')}</p>
            <button
              type="button"
              className="btn btn-success noti-create-button noti-menu-button"
              onClick={toggleCreateButton}
            >
              <span className="glyphicon glyphicon-plus" />
              <b className="margin-left-10">{l('VERSION.EDIT')}</b>
            </button>
          </div> */}
        <div className="absolute-privacy-table&form-container">
          {formVisibility && (
            <CreateVersionForm
              versionToBeModified={tempVersion}
              closingCB={() => {
                toggleFormVisibility(false);
                versionImportList();
              }}
              onModify={versionEdit}
              visible={formVisibility}
            />
          )}
          {!formVisibility && (
            <VersionTable
              data={data}
              visible={!formVisibility}
              toggleEditButton={toggleEditButton}
            />
          )}
        </div>
      </div>
      <Loader visible={loading} />
    </Layout>
  );
};

VersionPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  message: PropTypes.string,
  versionImportList: PropTypes.func.isRequired,
  activate: PropTypes.func.isRequired,
  deleteVersion: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  versionEdit: PropTypes.func.isRequired,
};

VersionPage.defaultProps = {
  data: [],
  loading: true,
  message: null,
};

const mapStateToProps = state => ({
  ...state.version,
});

const mapDispatchToProps = {
  onError: e => versionErrorAction(e),
  versionImportList: versionListLoadAction,
  activate: versionActivateAction,
  deleteVersion: versionDeleteAction,
  create: versionCreateAction,
  versionEdit: versionEditAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionPage);
