import React from 'react';
import { connect } from 'react-redux';
import { version } from '../libs/appInfo';
import { userLogin } from '../actions';

import Form from '../components/Form';
import Loader from '../components/Loader';
import { getGoogleLoginUri } from '../libs/auth';

const LoginPage = ({ userLogin, loading, message }) => {
  return (
    <div className="login-page" style={{ height: '100vh', width: '100vw' }}>
      <div className="login-box">
        <div className="login-logo" />
        <Form
          onSubmit={userLogin}
          validators={{ required: ['username', 'password'] }}
        >
          {({ controlInput, values, validations }) => (
            <div className="login-box-body" style={{ position: 'relative' }}>
              {message ? (
                <div className="alert alert-danger">
                  <i className="icon fa fa-exclamation-triangle" />
                  {message}
                </div>
              ) : (
                ''
              )}
              <div className="form-group has-feedback">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  {...controlInput('username')}
                />
                <span className="glyphicon glyphicon-user form-control-feedback" />
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  {...controlInput('password')}
                />
                <span className="glyphicon glyphicon-lock form-control-feedback" />
              </div>
              <div className="row">
                <div className="col-xs-12 form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-flat"
                    disabled={!(values.username && values.password)}
                  >
                    <span>LOGIN</span>
                  </button>
                </div>
                <Loader visible={loading} />
              </div>
            </div>
          )}
        </Form>
      </div>
      <div className="version">{`v${version}`}</div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.user,
});

export default connect(
  mapStateToProps,
  { userLogin }
)(LoginPage);
