import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from '../dropzone/Dropzone';
import './Upload.css';
import Progress from '../progress/Progress';
import { apiUrl, getConfig } from '../../../config';
import l from '../../../libs/localization';
import Loader from '../../Loader';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      progressState: null,
      error: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  onFilesAdded(files) {
    this.setState(
      prevState => ({
        files: prevState.files.concat(files),
      }),
      () => {
        this.uploadFiles();
      }
    );
  }

  async uploadFiles() {
    debugger;
    const { onComplete, onError } = this.props;
    const { files } = this.state;
    this.setState({
      uploadProgress: {},
      uploading: true,
      progressState: 'Uploading...',
    });
    const promises = [];
    files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);
      this.setState({ successfullUploaded: true, uploading: false });
      if (onComplete) onComplete();
    } catch (e) {
      console.log('upload error', e);
      this.setState({ successfullUploaded: false, uploading: false });
      if (onError) onError(e);
    } finally {
      this.setState({ files: [] });
    }
  }

  sendRequest(file) {
    const { target } = this.props;
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const { onError } = this.props;
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          if (req.status === 200) {
            const { uploadProgress } = this.state;
            const copy = { ...uploadProgress };
            copy[file.name] = {
              state: 'processed',
              percentage: 100,
            };
            console.log('status === 200', copy);
            this.setState({ uploadProgress: copy, progressState: 'Complete' });
            resolve(req.response);
          } else {
            const error = JSON.parse(req.response);
            console.log('status !== 200', error);
            if (error.exception) {
              onError(error.exception.message || error.message);
            }
            onError(error.message);
          }
        }
      };
      req.onerror = e => {
        onError(e);
      };
      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          const { uploadProgress } = this.state;
          const copy = { ...uploadProgress };
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          };
          console.log('progress', copy);
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener('load', event => {
        const { uploadProgress } = this.state;
        const copy = { ...uploadProgress };
        copy[file.name] = { state: 'done', percentage: 100 };
        console.log('load', copy);
        this.setState({ uploadProgress: copy, progressState: 'Processing...' });
      });

      req.upload.addEventListener('error', event => {
        const { uploadProgress } = this.state;
        const copy = { ...uploadProgress };
        copy[file.name] = { state: 'error', percentage: 0 };
        this.setState({ uploadProgress: copy, progressState: 'Error' });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append(file.name, file);
      const token = getConfig('token');

      req.open('POST', `${apiUrl}/api/v1/import/${target}`);
      if (token) req.setRequestHeader('Authorization', `bearer ${token}`);
      req.send(formData);
    });
  }

  render() {
    const { files, successfullUploaded, uploading, progressState } = this.state;
    const { title } = this.props;

    const dropzoneTitle = uploading ? progressState : title;

    return (
      <div className="Upload">
        <Loader visible={uploading} />
        <Dropzone
          title={dropzoneTitle}
          onFilesAdded={this.onFilesAdded}
          disabled={uploading || successfullUploaded}
        />
      </div>
    );
  }
}
Upload.propTypes = {
  target: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
export default Upload;
