import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const valutaFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
}).format;

class Question extends React.PureComponent {
  renderValue() {
    const { dataLayout } = this.props;
    const { value, fieldType } = dataLayout;

    if (!fieldType) return value;

    const valueType = typeof value;

    if (
      valueType === 'string' &&
      fieldType !== 'note' &&
      fieldType !== 'date'
    ) {
      if (value && value.indexOf('http') === 0) {
        return (
          <a href={value} target="_blank">
            {value}
          </a>
        );
      }
      return value;
    } else if (fieldType === 'eur' && value) {
      return valutaFormatter(value);
    } else if (fieldType === 'float' && value) {
      return value.toString().replace('.', ',');
    } else if ((fieldType === 'number' && value) || valueType === 'number') {
      return (Math.round(value * 100) / 100).toString().replace('.', ',');
    } else if (value === true) return <span className="text-green">✔️</span>;
    else if (value === false) return '✗️';
    else if (fieldType === 'note' && value) {
      const values = value.split('\n');
      if (values.length === 1) return value;
      const result = [];

      result.push(<br key="x" />);
      values.forEach((val, idx) => {
        result.push(val);
        result.push(<br key={idx} />);
      });
      result.push(<br key="end" />);
      return result;
    } else if (fieldType === 'date' && value) {
      const dateVal = moment(value);
      //console.log('date', value, dateVal, dateVal.format('DD/MM/YYYY'));
      return dateVal.format('DD/MM/YYYY');
    }

    return value;
  }

  render() {
    const { dataLayout } = this.props;

    return (
      <div className="">
        <label>{dataLayout.label}</label>: {this.renderValue()}
      </div>
    );
  }
}

Question.propTypes = {
  dataLayout: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  showIfempty: PropTypes.bool,
};

Question.defaultProps = {
  showIfempty: true,
};

export default Question;
