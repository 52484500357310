import { push } from 'connected-react-router';
import { callApi } from './commons.actions';
import {
  listCompressorsApi,
  loadCompressorApi,
  loadDataLayoutApi,
  ListCompressorUserApi,
} from '../api/compressors.api';
import { encodeParameters } from '../api/commons.api';

// actions
// export const COMPRESSORS_SET_FILTER = 'COMPRESSORS_SET_FILTER';
export const COMPRESSORS_LIST = 'COMPRESSORS_LIST';
export const COMPRESSORS_LIST_COMPLETE = 'COMPRESSORS_LIST_COMPLETE';
export const COMPRESSORS_LIST_ERROR = 'COMPRESSORS_LIST_ERROR';

export const COMPRESSORS_LOAD_FILTERS = 'COMPRESSORS_LOAD_FILTERS';
export const COMPRESSORS_LOAD_FILTERS_COMPLETE =
  'COMPRESSORS_LOAD_FILTERS_COMPLETE';
export const COMPRESSORS_LOAD_FILTERS_ERROR = 'COMPRESSORS_LOAD_FILTERS_ERROR';

export const COMPRESSOR_LOAD = 'COMPRESSOR_LOAD';
export const COMPRESSOR_LOAD_COMPLETE = 'COMPRESSOR_LOAD_COMPLETE';
export const COMPRESSOR_LOAD_ERROR = 'COMPRESSOR_LOAD_ERROR';

export const DATALAYOUT_LOAD = 'DATALAYOUT_LOAD';
export const DATALAYOUT_LOAD_COMPLETE = 'DATALAYOUT_LOAD_COMPLETE';
export const DATALAYOUT_LOAD_ERROR = 'DATALAYOUT_LOAD_ERROR';

export const USERCOMP_LIST = 'USERCOMP_LIST';
export const USERCOMP_LIST_COMPLETE = 'USERCOMP_LIST_COMPLETE';
export const USERCOMPN_LIST_ERROR = 'USERCOMPN_LIST_ERROR';

export const compressorListAction = callApi({
  apiCall: (p = {}) => listCompressorsApi(p),
  startAction: COMPRESSORS_LIST,
  successAction: COMPRESSORS_LIST_COMPLETE,
  errorAction: COMPRESSORS_LIST_ERROR,
});
export const ListCompressorUserAction = callApi({
  apiCall: ListCompressorUserApi,
  startAction: USERCOMP_LIST,
  successAction: USERCOMP_LIST_COMPLETE,
  errorAction: USERCOMPN_LIST_ERROR,
});

export const compressorSetFilterAction = (p = {}) => dispatch => {
  dispatch(push(encodeParameters(p, '/compressors')));
  dispatch(compressorListAction(p));
};

export const dataLayoutLoad = callApi({
  apiCall: loadDataLayoutApi,
  startAction: DATALAYOUT_LOAD,
  successAction: DATALAYOUT_LOAD_COMPLETE,
  errorAction: DATALAYOUT_LOAD_ERROR,
});

export const compressorLoadAction = callApi({
  apiCall: loadCompressorApi,
  startAction: COMPRESSOR_LOAD,
  successAction: COMPRESSOR_LOAD_COMPLETE,
  errorAction: COMPRESSOR_LOAD_ERROR,
  onStart: (params, dispatch, getState) => {
    const compressorState = getState().compressor;
    if (!compressorState.dataLayout && !compressorState.dataLayoutLoading) {
      dispatch(dataLayoutLoad());
    }
  },
  processResult: (result, params, dispatch, getState) => {
    console.log({ result });
  },
});
