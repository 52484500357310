import { http } from './commons.api';
import { getConfig } from '../config';

// import log from '../libs/logger';

export const listVersionApi = async () => {
  const response = await http.post('/admin/graphql', {
    query: `
    query q ($token: String!) 
    {
        root(token: $token ) {
            version {
                id
                shouldForceUpgrade
                currentIOSAppVersion
                currentAndroidAppVersion
                iOSAppStoreURL
                androidPlayStoreURL
            }
          }
    }
    `,
    variables: JSON.stringify({ token: getConfig('token') }),
  });
  return { data: response.data.root.version };
};
export const activateVersionApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! ) {
        activatePrivacyPolicy(
          token: $token
          version: $version
        ) {             
          version
          url
          createdAt
          validFrom
          userCount}
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params,
      }),
    })
    .then(() => {
      return listVersionApi();
    });
  return response;
};
export const deleteVersionApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! ) {
      deletePrivacyPolicy(
          token: $token
          version: $version
        )
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        version: params.version,
      }),
    })
    .then(() => {
      return listVersionApi();
    });
  return response;
};

export const versionCreateApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! $url: String! ) {
      addPrivacyPolicy(
          token: $token
          version: $version
          url: $url
        ){
          url
        }
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params,
      }),
    })
    .then(() => {
      return listVersionApi();
    });
  return response;
};

export const editVersionApi = async params => {
  if (params.data.shouldForceUpgrade) {
    params.data.shouldForceUpgrade = true;
  } else {
    params.data.shouldForceUpgrade = false;
  }

  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation addVersions ($token: String! $shouldForceUpgrade: Boolean $currentIOSAppVersion: String! $id: String! $currentAndroidAppVersion: String!) {
      prepareVersions(
          id: $id
          token:$token
          shouldForceUpgrade: $shouldForceUpgrade
          currentIOSAppVersion: $currentIOSAppVersion
          currentAndroidAppVersion: $currentAndroidAppVersion
        ) { id shouldForceUpgrade currentIOSAppVersion currentAndroidAppVersion}
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params.data,
      }),
    })
    .then(() => {
      return listVersionApi(params.filters);
    });
  console.log('ressssssss..........,', response);
  return response;
};
