import React, { /* useState, useCallback, */ useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';

import { compressorLoadAction } from '../actions';

import Layout from '../layouts/Layout';
import Loader from '../components/Loader';
import ModelDetail from '../components/modelDetail/ModelDetail';
import l from '../libs/localization';

const CompressorDetailPage = ({
  match,
  location,
  loading,
  dataLayoutLoading,
  data,
  dataLayout,
  message,
  mode,
  compressorLoad,
}) => {
  debugger;
  const compressor = data ? data.data : {};

  const title = `${compressor.partNumber} - ${(compressor.commercialDescription,
  console.log(
    'compressor commercialDescription',
    compressor.commercialDescription
  ),
  console.log('compressor', compressor))}`;

  /*
  const [id, tag, tags, title] = [
    data.id,
    data.tag,
    data.tags,
    project ? `${project.db_id} - ${project.title}` : null,
  ];
*/
  const ready = !dataLayoutLoading && !loading && data && dataLayout;
  console.log('ready', ready);

  const { id: queryId } = match.params;
  useEffect(() => {
    compressorLoad({ id: queryId });
    console.log('compresser data', data);
  }, [queryId, location.search]);

  return (
    <Layout>
      {message ? (
        <div className="callout callout-danger">
          <p>{message}</p>
        </div>
      ) : (
        ''
      )}
      <div className="page-nav-links">
        <Link to="/compressors" className="btn btn-primary">
          {l('COMMON.BACK_TO_LIST')}
        </Link>
      </div>
      {ready ? (
        <div className="project-detail">
          <h1>{title}</h1>
          <ModelDetail model={data} dataLayout={dataLayout.tree} showAll />
        </div>
      ) : (
        <Loader visible={loading || dataLayoutLoading} />
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.compressor,
});

const mapDispatchToProps = {
  compressorLoad: compressorLoadAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompressorDetailPage);
