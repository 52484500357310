import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';

import { setService } from './libs/helpers';
import CompressorListPage from './containers/CompressorListPage';
import CompressorDetailPage from './containers/CompressorDetailPage';
import NotificationListPage from './containers/NotificationListPage';
import FilesImportPage from './containers/FilesImportPage';
import UserMgmtPage from './containers/UserMgmtPage';
import VersionPage from './containers/VersionPage';

import PrivacyPage from './containers/PrivacyPage';
import DealerListPage from './containers/DealerListPage';
import DealerDetailPage from './containers/DealerDetailPage';
import ShopListPage from './containers/ShopListPage';
import ShopDetailPage from './containers/ShopDetailPage';

const routes = authorized => [
  {
    label: 'Home',
    component: HomePage,
    path: '/',
    exact: true,
    iconClass: 'fa fa-home',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Compressors',
    component: CompressorListPage,
    path: '/compressors',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Dealers',
    component: DealerListPage,
    path: '/dealers',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Dealers details',
    component: DealerDetailPage,
    path: '/dealers/:id',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: false,
    authorized,
  },
  {
    label: 'Shops',
    component: ShopListPage,
    path: '/shops',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Shop details',
    component: ShopDetailPage,
    path: '/shops/:id',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: false,
    authorized,
  },
  {
    label: 'Imports',
    component: FilesImportPage,
    path: '/imports',
    exact: true,
    iconClass: 'glyphicon glyphicon-import',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Iniziativa',
    component: CompressorDetailPage,
    path: '/compressors/:id',
    exact: true,
    iconClass: 'fa fa-list',
    showInMenu: false,
    authorized,
  },
  {
    label: 'Notifications',
    component: NotificationListPage,
    path: '/notifications',
    exact: true,
    iconClass: 'fa fa-bell',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Privacy Policy',
    component: PrivacyPage,
    path: '/privacy',
    exact: true,
    iconClass: 'glyphicon glyphicon-tag',
    showInMenu: true,
    authorized,
  },
  {
    label: 'Versions',
    component: VersionPage,
    path: '/version',
    exact: true,
    iconClass: 'glyphicon glyphicon-tag',
    showInMenu: true,
    authorized,
  },
  {
    label: 'User Management',
    component: UserMgmtPage,
    path: '/users',
    exact: true,
    iconClass: 'glyphicon glyphicon-user',
    showInMenu: true,
    authorized,
  },

  {
    label: 'Login',
    component: LoginPage,
    path: '/login',
    exact: true,
    showInMenu: false,
  },
];

setService('routes', routes(true));

export default routes;
