import moment from 'moment';

const { location } = global;

export const wait = durationMs =>
  new Promise(fulfill => setTimeout(fulfill, durationMs));

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64));
};

export const refreshPage = () => {
  // eslint-disable-next-line no-self-assign
  location.href = location.href;
};

export const getAddress = () => `${location.protocol}//${location.host}/`;

export const parseUrlParameters = queryString => {
  let data = queryString;
  if (data[0] === '?') [, data] = data.split('?');
  if (data[0] === '#') [, data] = data.split('#');

  const parameters = {};

  data.split('&').forEach(t => {
    const [k, v] = t.split('=');
    if (!k) return;
    parameters[k] = decodeURIComponent(v);
  });

  return parameters;
};

export const parseArrayParameterValue = param => {
  let value = param;
  if (param && param.indexOf('::') >= 0) {
    value = value.split('::').filter(v => v !== '');
  }

  return value;
};

export const formatDate = (date, empty = '-', format = 'DD/MM/YYYY') => {
  if (!date || date === 0) return empty;
  return moment(date).format(format);
};

export const formatDateTime = (
  date,
  empty = '-',
  format = 'DD/MM/YYYY HH.mm'
) => formatDate(date, empty, format);

const services = {};
export const getService = key => services[key];
export const setService = (key, service) => {
  services[key] = service;
};
