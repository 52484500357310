import React, { /* useState, useCallback, */ useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';

import { shopLoadAction } from '../actions';
import Card from '../components/Card';
import Layout from '../layouts/Layout';
import Loader from '../components/Loader';
import ModelDetail from '../components/modelDetail/ModelDetail';
import l from '../libs/localization';

const ShopDetailPage = ({
  match,
  location,
  loading,
  dataLayoutLoading,
  data,
  dataLayout,
  message,
  mode,
  shopLoad,
}) => {
  const shop = data ? data.data : {};
  console.log('Shop: ', shop);
  let title;
  try {
    title = `${shop.shopname ? shop.shopname : ''} - ${
      shop.brand1 ? shop.brand1 : ''
    }`;
  } catch (e) {
    title = 'Detail';
  }
  /*
  const [id, tag, tags, title] = [
    data.id,
    data.tag,
    data.tags,
    project ? `${project.db_id} - ${project.title}` : null,
  ];
*/
  //console.log('READY: ',  !dataLayoutLoading, !loading , data , dataLayout)
  const ready = !dataLayoutLoading && !loading && data; /* && dataLayout*/
  //console.log('READY: ' , ready)
  const { id: queryId } = match.params;
  useEffect(() => {
    shopLoad({ id: queryId });
  }, [queryId, location.search]);

  return (
    <Layout>
      {message ? (
        <div className="callout callout-danger">
          <p>{message}</p>
        </div>
      ) : (
        ''
      )}
      <div className="page-nav-links">
        <Link to="/shops" className="btn btn-primary">
          {l('COMMON.BACK_TO_LIST')}
        </Link>
      </div>

      {ready ? (
        // <div className="project-detail">
        //   <h1>{shop.partNumber ? shop.commercialDescription : 'TEST'}</h1>
        //   <ModelDetail model={data} dataLayout={dataLayout.tree} showAll />
        // </div>
        <div className="project-detail">
          <h1>
            {data.shopname ? data.shopname : ''} -{' '}
            {data.brand ? data.brand : ''}
          </h1>
          <Card>
            {/* <h2 className="">{'Details'}</h2> */}
            <div className="col-xs-2 dealerDetails">
              <p>
                <b>{l('SHOP.SHOP_NAME')}</b>
              </p>
              <p>{l('SHOP.SHOP_BRAND')}:</p>
              <p>{l('SHOP.SHOP_COUNTRY_NAME')}:</p>
              <p>{l('SHOP.SHOP_COUNTRY_CODE')}:</p>
              <p>{l('SHOP.SHOP_NAME')}:</p>
              <p>{l('SHOP.SHOP_ICON')}:</p>
              <p>{l('SHOP.SHOP_URL_HOME')}:</p>
              <p>{l('SHOP.SHOP_URL_LUBRICANTS')}:</p>
              <p>{l('SHOP.SHOP_URL_TOOLS')}: </p>
              <p>{l('SHOP.SHOP_URL_COMPRESSORS')}: </p>
              <p>{l('SHOP.SHOP_URL_AIR_INSTALLATIONS')}: </p>
            </div>
            <div className="col-xs-4">
              <p>{data.shopname ? data.shopname : '-'}</p>
              <p>{data.brand ? data.brand : '-'}</p>
              <p>{data.countryname ? data.countryname : '-'}</p>
              <p>{data.countrycode ? data.countrycode : '-'}</p>
              <p>{data.icon ? data.icon : '-'}</p>
              <p>{data.urlparts ? data.urlparts : '-'}</p>
              <p>{data.urlhome ? data.urlhome : '-'}</p>
              <p>{data.urllubricants ? data.urllubricants : '-'}</p>
              <p>{data.urltools ? data.urltools : '-'}</p>
              <p>{data.urlcompressor ? data.urlcompressor : '-'}</p>
              <p>{data.urlairinstallations ? data.urlairinstallations : '-'}</p>
            </div>
          </Card>

          {/* <ModelDetail model={data} dataLayout={dataLayout.tree} showAll /> */}
        </div>
      ) : (
        <Loader visible={loading || dataLayoutLoading} />
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.shop,
});

const mapDispatchToProps = {
  shopLoad: shopLoadAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopDetailPage);
