import React from 'react';
import PropTypes from 'prop-types';
import l from '../../libs/localization';
import Form from '../Form';

const ShopListFilter = ({ filters, onFilter, rightButtons }) => {
  return (
    <Form onSubmit={onFilter} initialValue={filters}>
      {({ controlInput }) => (
        <div className="flex">
          <div className="filters">
            <div className="input-group">
              <span className="input-group-addon">
                <span className="glyphicon glyphicon-search" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={l('DEALER.SEARCH_PLACEHOLDER')}
                {...controlInput('search')}
              />
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="btn btn-secondary">
              <span className="glyphicon glyphicon-filter" />
              <b className="margin-left-10">{l('COMMON.SEARCH')}</b>
            </button>

            {rightButtons && <div className="pull-right">{rightButtons}</div>}
          </div>
        </div>
      )}
    </Form>
  );
};

ShopListFilter.propTypes = {
  rightButtons: PropTypes.node,
};

ShopListFilter.defaultProps = {
  rightButtons: null,
};

export default ShopListFilter;
