import React from 'react';
import PropTypes from 'prop-types';
import SubSection from './SubSection';
import Card from '../Card';

const Section = ({ mode, dataLayout, model, structureIndex, showAll }) => {
  const effectiveShowAll = showAll || mode === 'edit';

  const contents = dataLayout.subsections
    .filter(q => effectiveShowAll || q.answers)
    .map((subsection, idx) => (
      <SubSection
        key={idx}
        dataLayout={subsection}
        model={model}
        showAll={showAll}
      />
    ));

  if (!dataLayout.title) {
    return contents;
  }

  return <Card header={<h4>{dataLayout.title}</h4>}>{contents}</Card>;
};

Section.propTypes = {
  dataLayout: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showAll: PropTypes.bool,
};

Section.defaultProps = {
  showAll: true,
};

export default Section;
