import React from 'react';
import PropTypes from 'prop-types';

import l from '../../libs/localization';
import Form from '../Form';
import { userListExportApi } from '../../api/users.api';

const saveData = (data, fileName = 'users_export.csv') => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const blob = new Blob([data], { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggleForm = this.toggleForm.bind(this);
  }

  toggleForm() {
    this.setState(prevState => ({ show: !prevState.show }));
  }

  render() {
    const { onCreate, filters } = this.props;
    const { show } = this.state;
    return (
      <>
        {!show && (
          <>
            <button
              type="button"
              className="btn btn-success margin-left-10"
              onClick={this.toggleForm}
            >
              <span className="glyphicon glyphicon-plus" />
              <b className="margin-left-10">
                {show ? l('COMMON.CANCEL') : l('USERS.ADD')}
              </b>
            </button>
            <button
              type="button"
              className="btn btn-primary noti-create-button noti-menu-button"
              onClick={() =>
                userListExportApi().then(res =>
                  res.blob().then(blob => {
                    saveData(blob);
                  })
                )
              }
            >
              <span className="glyphicon glyphicon-plus" />
              <b>{l('USERS.LIST')}</b>
            </button>
          </>
        )}

        {show && (
          <Form
            onSubmit={v => {
              onCreate({ data: v, filters });
            }}
            className="w-100"
            initialValue={{ type: 'admin' }}
            validators={{ required: ['email', 'name'] }}
          >
            {({ controlInput, values }) => (
              <div className="flex padding-bottom w-100">
                <div className="flex-column user-create-card w-100">
                  <h3>{l('USERS.ADD')}</h3>
                  <h4>{l('USERS.EMAIL')}</h4>
                  <div className="input-group">
                    <input
                      rows="8"
                      type="email"
                      className="user-text form-control"
                      placeholder={l('USERS.EMAIL')}
                      {...controlInput('email')}
                    />
                  </div>
                  <h4>{l('USERS.NAME')}</h4>
                  <div className="input-group">
                    <input
                      rows="8"
                      type="text"
                      className="user-text form-control"
                      placeholder={l('USERS.NAME')}
                      {...controlInput('name')}
                    />
                  </div>
                  <div className="d-flex users-buttons">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.toggleForm}
                    >
                      <span className="glyphicon glyphicon-eject" />
                      <b>{l('COMMON.CANCEL')}</b>
                    </button>
                    <button
                      type="submit"
                      disabled={!values.email || !values.type || !values.name}
                      className={
                        values.email && values.type && values.name
                          ? 'btn btn-success'
                          : 'btn btn-default'
                      }
                    >
                      <span className="glyphicon glyphicon-save" />
                      <b>{l('USERS.SAVE')}</b>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Form>
        )}
      </>
    );
  }
}

CreateUser.propTypes = {
  onCreate: PropTypes.func,
  filters: PropTypes.shape({ type: PropTypes.string, search: PropTypes.string })
    .isRequired,
};

CreateUser.defaultProps = {
  onCreate: () => {},
};

export default CreateUser;
