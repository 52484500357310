import { callApi } from './commons.actions';
import {
  listVersionApi,
  activateVersionApi,
  deleteVersionApi,
  versionCreateApi,
  editVersionApi,
} from '../api/version.api';

export const VERSION_LIST = 'VERSION_LIST';
export const VERSION_LIST_COMPLETE = 'VERSION_LIST_COMPLETE';
export const VERSION_LIST_ERROR = 'VERSION_LIST_ERROR';

export const VERSION_ERROR = 'VERSION_ERROR';

export const versionListLoadAction = callApi({
  // apiCall: listVersionApi,
  apiCall: (p = {}) => listVersionApi(p),
  startAction: VERSION_LIST,
  successAction: VERSION_LIST_COMPLETE,
  errorAction: VERSION_LIST_ERROR,
});

export const versionActivateAction = callApi({
  apiCall: (p = {}) => {
    return activateVersionApi(p);
  },
  startAction: VERSION_LIST,
  successAction: VERSION_LIST_COMPLETE,
  errorAction: VERSION_LIST_ERROR,
});

export const versionDeleteAction = callApi({
  apiCall: (p = {}) => {
    return deleteVersionApi(p);
  },
  startAction: VERSION_LIST,
  successAction: VERSION_LIST_COMPLETE,
  errorAction: VERSION_LIST_ERROR,
});

export const versionErrorAction = e => ({
  type: VERSION_ERROR,
  error: e,
});

export const versionCreateAction = callApi({
  apiCall: (p = {}) => {
    return versionCreateApi(p);
  },
  startAction: VERSION_LIST,
  successAction: VERSION_LIST_COMPLETE,
  errorAction: VERSION_LIST_ERROR,
});
export const versionEditAction = callApi({
  apiCall: (p = {}) => {
    return editVersionApi(p);
  },
  startAction: VERSION_LIST,
  successAction: VERSION_LIST_COMPLETE,
  // startAction: NOTIFICATION_LIST_CHANGE,
  // successAction: NOTIFICATION_LIST_CHANGE_SUCCESS,
  errorAction: VERSION_LIST_ERROR,
  // processResult: (result, params, dispatch) =>
  //   dispatch(notificationListAction()),
});
