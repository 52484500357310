/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import PropTypes from 'prop-types';

import l from '../../libs/localization';
import Form from '../Form';

const moment = require('moment');

const CreateNotificationForm = props => {
  const {
    closingCB,
    onCreate,
    onModify,
    visible,
    formType,
    notificationToBeModified,
  } = props;
  const [tempNotification, setTempNotification] = useState({});
  useEffect(() => {
    setTempNotification({
      ...notificationToBeModified.data,
      plannedSendAt: moment(
        (notificationToBeModified &&
          notificationToBeModified.data &&
          notificationToBeModified.data.plannedSendAt) ||
          null
      ).format('YYYY-MM-DDTHH:mm'),
    });
  }, [notificationToBeModified]);
  let component = ( // This is the component in 'create' mode
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">{l('NOTIFICATION.CREATE')}</h3>
      </div>
      {/* <!-- /.box-header --> */}
      {/* <!-- form start --> */}
      <Form
        initialValue={{
          text: undefined,
          plannedSendAt: moment
            .utc()
            .add(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm'),
          language: undefined,
        }}
        onSubmit={onCreate}
        className="w-100"
        role="form"
      >
        {({ controlInput, values }) => (
          <div className="box-body">
            <div className="form-group">
              <label htmlFor="notificationText">{l('NOTIFICATION.TEXT')}</label>
              <textarea
                id="notificationText"
                className="form-control"
                rows="8"
                type="text"
                placeholder={l('NOTIFICATION.TEXT')}
                {...controlInput('text')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="notificationPlannedAt">
                {l('NOTIFICATION.PLANNED_AT')}
              </label>
              <input
                id="notificationPlannedAt"
                type="datetime-local"
                min={moment.utc().format('YYYY-MM-DDTHH:mm')}
                value={moment.utc().format('YYYY-MM-DDTHH:mm')}
                className="form-control"
                {...controlInput('plannedSendAt')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="notificationLanguage">
                {l('NOTIFICATION.LANGUAGE')}
              </label>
              <select
                id="notificationLanguage"
                className="form-control"
                {...controlInput('language')}
              >
                <option>{l(`COMMON.CHOOSE`)}</option>
                <option value="en">{l(`LANGS.en`)}</option>
                <option value="it">{l(`LANGS.it`)}</option>
                <option value="de">{l(`LANGS.de`)}</option>
                <option value="fr">{l(`LANGS.fr`)}</option>
                <option value="es">{l(`LANGS.es`)}</option>
              </select>
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger m-0"
                onClick={closingCB}
              >
                <span className="glyphicon glyphicon-remove" />
                <b className="margin-left-10">{l('COMMON.CANCEL')}</b>
              </button>
              <button
                type="submit"
                disabled={!values.plannedSendAt || !values.text}
                className={
                  values.plannedSendAt && values.text
                    ? 'btn btn-success m-0'
                    : 'btn btn-default m-0'
                }
              >
                <span className="glyphicon glyphicon-floppy-disk" />
                <b className="margin-left-10">{l('NOTIFICATION.SAVE_DRAFT')}</b>
              </button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
  if (formType === 'modify') {
    // reassign component if mode is 'mofidy'
    component = (
      <div className="box box-primary">
        <div className="box-header with-border">
          <h3 className="box-title">{l('NOTIFICATION.MODIFY')}</h3>
        </div>
        {/* <!-- /.box-header --> */}
        {/* <!-- form start --> */}
        <Form
          initialValue={notificationToBeModified.data}
          onSubmit={e => {
            setTempNotification(oldTempNotification => {
              const mergedNotification = { ...oldTempNotification, ...e };
              onModify({
                data: mergedNotification,
                filters: notificationToBeModified.filters,
              });
              return mergedNotification;
            });
          }}
          className="w-100"
          role="form"
        >
          {({ controlInput, values }) => {
            values.plannedSendAt = moment
              .utc(values.plannedSendAt)
              .format('YYYY-MM-DDTHH:mm');
            return (
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="notificationText">
                    {l('NOTIFICATION.TEXT')}
                  </label>
                  <textarea
                    id="notificationText"
                    className="form-control"
                    rows="8"
                    type="text"
                    placeholder={l('NOTIFICATION.TEXT')}
                    {...controlInput('text')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="notificationPlannedAt">
                    {l('NOTIFICATION.PLANNED_AT')}
                  </label>
                  <input
                    id="notificationPlannedAt"
                    type="datetime-local"
                    min={moment.utc().format('YYYY-MM-DDTHH:mm')}
                    value={moment.utc().format('YYYY-MM-DDTHH:mm')}
                    className="form-control"
                    {...controlInput('plannedSendAt')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="notificationLanguage">
                    {l('NOTIFICATION.LANGUAGE')}
                  </label>
                  <select
                    id="notificationLanguage"
                    className="form-control"
                    {...controlInput('language')}
                  >
                    <option>{l(`COMMON.CHOOSE`)}</option>
                    <option value="en">{l(`LANGS.en`)}</option>
                    <option value="it">{l(`LANGS.it`)}</option>
                    <option value="de">{l(`LANGS.de`)}</option>
                    <option value="fr">{l(`LANGS.fr`)}</option>
                    <option value="es">{l(`LANGS.es`)}</option>
                  </select>
                </div>

                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-danger m-0"
                    onClick={closingCB}
                  >
                    <span className="glyphicon glyphicon-remove" />
                    <b className="margin-left-10">{l('COMMON.CANCEL')}</b>
                  </button>
                  <button
                    type="submit"
                    disabled={!values.plannedSendAt || !values.text}
                    className={
                      values.plannedSendAt && values.text
                        ? 'btn btn-success m-0'
                        : 'btn btn-default m-0'
                    }
                  >
                    <span className="glyphicon glyphicon-floppy-disk" />
                    <b className="margin-left-10">{l('NOTIFICATION.SAVE')}</b>
                  </button>
                </div>
              </div>
            );
          }}
        </Form>
      </div>
    );
  }
  return (
    <ReactCSSTransitionGroup transitionName="notification-form-transition">
      {visible && component}
    </ReactCSSTransitionGroup>
  );
};

CreateNotificationForm.propTypes = {
  onCreate: PropTypes.func,
  onModify: PropTypes.func,
  notificationToBeModified: PropTypes.shape({
    id: PropTypes.string,
    statusMessage: PropTypes.string,
    text: PropTypes.string,
    plannedSendAt: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.string,
    language: PropTypes.string,
  }),
  closingCB: PropTypes.func.isRequired,
  formType: PropTypes.oneOf(['create', 'modify']).isRequired,
  visible: PropTypes.bool.isRequired,
};

CreateNotificationForm.defaultProps = {
  onCreate: () => {},
  onModify: () => {},
  notificationToBeModified: {
    id: null,
    statusMessage: null,
    text: null,
    plannedSendAt: null,
    createdAt: null,
    status: null,
    language: null,
  },
};

export default CreateNotificationForm;
