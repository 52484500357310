import React from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import Card from '../Card';

const SubSection = ({ mode, dataLayout, model, structureIndex, showAll }) => {
  const effectiveShowAll = showAll || mode === 'edit';

  const contents = dataLayout.questions
    .filter(q => effectiveShowAll || q.hasValue)
    .map((question, idx) => (
      <Question
        key={idx}
        dataLayout={question}
        model={model}
        showAll={showAll}
      />
    ));

  if (!dataLayout.title) {
    console.log('content--', contents);
    return contents;
  }

  return <Card header={<h5>{dataLayout.title}</h5>}>{contents}</Card>;
};

SubSection.propTypes = {
  dataLayout: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showAll: PropTypes.bool,
};

SubSection.defaultProps = {
  showAll: true,
};

export default SubSection;
