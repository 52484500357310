import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';

import './config';

import App from './App';
// import './assets/daterangepicker.css';
import './assets/styles.css';
// import 'bootstrap/dist/css/bootstrap.css';
// require('babel-core/register');
// require('babel-polyfill');

const rootElement = 'root';

const store = configureStore();

const renderApp = AppToRender => {
  render(
    <Provider store={store}>
      <AppToRender />
    </Provider>,
    document.getElementById(rootElement)
  );
};

renderApp(App);

/*
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    renderApp(NextApp);
  });
}
*/
