import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../libs/helpers';
import l from '../../libs/localization';

const moment = require('moment');

const StatusSwitcher = (onChange, status, id, filters) => {
  if (status !== 'draft' && status !== 'planned') {
    return (
      <span
        className={
          (status === 'error' ? 'notif-status-error ' : '') +
          (status === 'sent' ? 'notif-status-sent ' : '')
        }
      >
        {status}
      </span>
    );
  }
  return (
    <select
      value={status}
      onChange={e =>
        onChange({
          data: { status: e.target.value, id },
          filters,
        })
      }
    >
      <option value="draft">Draft</option>
      <option value="planned">Planned</option>
    </select>
  );
};
class Version extends React.Component {
  constructor(props) {
    super(props);
    const { r } = this.props;
    this.state = {
      r,
    };
    this.tablerefs = {
      text: React.createRef(),
      plannedSendAt: React.createRef(),
    };
    this.openModifyForm = this.openModifyForm.bind(this);
  }

  componentWillReceiveProps(newprops) {
    if (newprops.r) {
      this.setState({ r: newprops.r });
    }
  }

  openModifyForm() {
    const { r: data } = this.state;
    const { modifyNotification, filters } = this.props;

    if (modifyNotification) {
      modifyNotification({ data, filters });
    }
  }

  render() {
    const {
      filters,
      notificationDelete,
      notificationChangeStatus,
      r: rprop,
    } = this.props;
    const { r } = this.state;
    return (
      <tr>
        <td className="notification-text">{r.currentIOSAppVersion}</td>
        <td>{r.currentAndroidAppVersion}</td>
        <td>{r.androidPlayStoreURL}</td>
        <td>{r.iOSAppStoreURL}</td>

        <td>
          <div className="notification-tooltip">
            {/* {r.status !== 'error' && r.status !== 'sent' && (
              <span className="notification-tooltip-text">
                {l('NOTIFICATION.EDIT-TOOLTIP')}
              </span>
            )} */}
            <button
              type="button"
              className=" btn-outline btn-notification-action"
              onClick={this.openModifyForm}
              // disabled={r.status === 'error' || r.status === 'sent'}
            >
              <span className="glyphicon glyphicon-pencil" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
}
Version.propTypes = {
  r: PropTypes.shape({
    id: PropTypes.string,
    currentIOSAppVersion: PropTypes.string,
    currentAndroidAppVersion: PropTypes.string,
    androidPlayStoreURL: PropTypes.string,
    iOSAppStoreURL: PropTypes.string,
    shouldForceUpgrade: PropTypes.any,
  }).isRequired,

  modifyNotification: PropTypes.func.isRequired,
};

Version.defaultProps = {
  filters: {},
};

export default Version;
