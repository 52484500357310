import _ from 'lodash';

export const isNullOrUndef = val => val === null || val === undefined;

export const getFieldValue = (data, fieldInfo) => {
  const val = _.get(data.data, fieldInfo.dotpath);
  if (val !== null && typeof val === 'object') return null;
  return val;
};

export const getFieldWarning = (data, fieldInfo, structureIndex = null) => {
  const root =
    structureIndex === null ? data.project : data.structures[structureIndex];
  return !!_.get(root, `${fieldInfo.dotPath}_warning`);
};

export const normalizeTreeStructure = treeDataLayout => {
  treeDataLayout.forEach(page => {
    page.sections.forEach(section => {
      section.subsections.forEach(subsection => {
        subsection.questions.forEach(question => {
          if (question.isProject) {
            section.isProject = true;
            subsection.isProject = true;
          }

          if (question.isProperty || question.isStructure) {
            page.isStructure = true;
            section.isStructure = true;
            subsection.isStructure = true;
            question.isStructure = true;
          }
        });
      });
    });
  });
};

const hasValue = (value, warning) =>
  (value !== null && value !== undefined && value !== 0 && value !== '') ||
  warning;

export const digestStructureMetadata = (
  structureIndex,
  treeDataLayout,
  data
) => {
  const structureMetadata = {
    title: `Struttura ${structureIndex + 1}`,
    pages: [],
    answers: 0,
  };

  treeDataLayout
    .filter(el => el.isStructure)
    .forEach((page, idx) => {
      const pageMetadata = {
        title: page.title,
        isProject: page.isProject,
        isStructure: page.isStructure,
        sections: [],
        answers: 0,
        warnings: 0,
      };
      structureMetadata.pages.push(pageMetadata);

      page.sections
        .filter(el => el.isStructure)
        .forEach(section => {
          const sectionMetadata = {
            title: section.title,
            isProject: section.isProject,
            isStructure: section.isStructure,
            subsections: [],
            answers: 0,
          };
          pageMetadata.sections.push(sectionMetadata);

          section.subsections
            .filter(el => el.isStructure)
            .forEach(subsection => {
              const subsectionMetadata = {
                title: subsection.title,
                isProject: subsection.isProject,
                isStructure: subsection.isStructure,
                questions: [],
                answers: 0,
              };

              subsection.questions
                .filter(question => question.isStructure)
                .forEach(question => {
                  const value = getFieldValue(data, question, structureIndex);
                  const warning = getFieldWarning(
                    data,
                    question,
                    structureIndex
                  );

                  const questionMetadata = { ...question, value, warning };
                  subsectionMetadata.questions.push(questionMetadata);

                  if (hasValue(value, warning)) {
                    pageMetadata.answers++;
                    sectionMetadata.answers++;
                    subsectionMetadata.answers++;
                    questionMetadata.hasValue = true;
                  }
                  if (warning) {
                    pageMetadata.warnings++;
                    data.metadata.warnings++;
                  }
                });
              sectionMetadata.subsections.push(subsectionMetadata);
            });
        });
    });

  return structureMetadata;
};

export const digestDealerDate = (data, treeDataLayout) => {
  // TODO
};

export const digestCompressorData = (data, treeDataLayout) => {
  // preprocess project data

  data.metadata = { pages: [], answers: 0 };
  const { metadata } = data;

  treeDataLayout.forEach((page, idx) => {
    const pageMetadata = {
      title: page.title,
      sections: [],
      answers: 0,
      warnings: 0,
    };
    metadata.pages.push(pageMetadata);

    page.sections.forEach(section => {
      const sectionMetadata = {
        title: section.title,
        subsections: [],
        answers: 0,
      };
      pageMetadata.sections.push(sectionMetadata);

      section.subsections.forEach(subsection => {
        const subsectionMetadata = {
          title: subsection.title,
          questions: [],
          answers: 0,
        };
        sectionMetadata.subsections.push(subsectionMetadata);

        subsection.questions.forEach(question => {
          const value = getFieldValue(data, question);
          const warning = getFieldWarning(data, question);

          const questionMetadata = { ...question, value, warning };
          subsectionMetadata.questions.push(questionMetadata);

          if (hasValue(value, warning)) {
            pageMetadata.answers++;
            sectionMetadata.answers++;
            subsectionMetadata.answers++;
            questionMetadata.hasValue = true;
          }
        });
      });
    });
  });
};
