import { reducerFromMap } from './common.reducers';

import {
  USERS_LIST,
  USERS_LIST_COMPLETE,
  USERS_LIST_ERROR,
  USER_CREATED,
  USER_DELETED,
  ROUTER_LOCATION_CHANGE,
  USERS_FILTER,
  USERS_CHANGE_PAGE,
} from '../actions';

const initialState = {
  loading: false,
  filters: { type: 'admin' },
  data: null,
  page: 1,
  message: null,
};

const reducerMap = {};

reducerMap[ROUTER_LOCATION_CHANGE] = (state, action) => {
  if (action.payload.location.pathname !== '/users') return state;
  return {
    ...state,
    filters: { type: 'admin' },
    message: null,
  };
};
reducerMap[USERS_LIST] = state => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[USERS_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data.data,
  filters: action.data.filters,
});
reducerMap[USER_CREATED] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data.data,
});
reducerMap[USER_DELETED] = (state, action) => ({
  ...state,
  loading: false,
  data: [action.data, ...state.data],
});
reducerMap[USERS_LIST_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error.message,
});
reducerMap[USERS_FILTER] = (state, action) => ({
  ...state,
  loading: false,
  filters: action.data,
  page: 1,
});

reducerMap[USERS_CHANGE_PAGE] = (state, action) => ({
  ...state,
  page: action.data,
});
export default reducerFromMap(reducerMap, initialState);
