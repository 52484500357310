import { reducerFromMap } from './common.reducers';

import {
  VERSION_LIST,
  VERSION_LIST_COMPLETE,
  VERSION_LIST_ERROR,
  ROUTER_LOCATION_CHANGE,
  //   VERSION_CREATE,
  //   VERSION_CREATE_COMPLETE,
  //   VERSION_CREATE_ERROR,
  //   VERSION_DELETED,
  //   VERSION_LIST_CHANGE,
  //   VERSION_LIST_CHANGE_SUCCESS,
} from '../actions';

const moment = require('moment');

const initialState = {
  loading: false,
  search: '',
  filters: {
    dateFrom: moment()
      .subtract(6, 'months')
      .format('YYYY-MM-DD'),
    dateTo: moment()
      .endOf('year')
      .format('YYYY-MM-DD'),
  },
  data: null,
  message: null,
  filterData: null,
  filterDataLoading: false,
  filterDataMessage: null,
};

const reducerMap = {};

reducerMap[ROUTER_LOCATION_CHANGE] = (state, action) => {
  if (action.payload.location.pathname !== '/version') return state;
  return {
    ...state,
    filters: {
      dateFrom: moment
        .utc()
        .subtract(6, 'months')
        .format('YYYY-MM-DD'),
      dateTo: moment
        .utc()
        .endOf('year')
        .format('YYYY-MM-DD'),
    },
    message: null,
  };
};

reducerMap[VERSION_LIST] = state => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[VERSION_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data.data,
  filters: action.data.filters,
});
// reducerMap[VERSION_CREATE] = (state, action) => ({
//   ...state,
//   message: null,
//   loading: true,
// });
// reducerMap[VERSION_CREATE_ERROR] = (state, action) => ({
//   ...state,
//   message: null,
//   loading: true,
// });
// reducerMap[VERSION_CREATE_COMPLETE] = (state, action) => ({
//   ...state,
//   loading: false,
//   // data: [action.data, ...state.data],
// });
// reducerMap[VERSION_CREATE_ERROR] = (state, action) => {
//   console.log(
//     '+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++VERSION_LIST_ERROR'
//   );
//   let errorMessage = '';
//   debugger;
//   if (action.errors)
//     errorMessage += action.errors.map(error => `${error.message}\n`);
//   if (action.error && action.error.message)
//     errorMessage += `UNHANDLED: ${action.error.message}`;
//   return {
//     ...state,
//     loading: false,
//     message: errorMessage.slice(0, -1),
//   };
// };
// reducerMap[VERSION_LIST_CHANGE] = (state, action) => ({
//   ...state,
//   loading: true,
// });
// reducerMap[VERSION_LIST_CHANGE_SUCCESS] = (state, action) => ({
//   ...state,
//   loading: false,
// });
// reducerMap[VERSION_DELETED] = (state, action) => ({
//   ...state,
//   loading: false,
//   data: [action.data, ...state.data],
// });
reducerMap[VERSION_LIST_ERROR] = (state, action) => {
  let errorMessage = '';
  if (action.errors)
    errorMessage += action.errors.map(error => `${error.message}\n`);
  if (action.error && action.error.message)
    errorMessage += `UNHANDLED: ${action.error.message}`;
  return {
    ...state,
    loading: false,
    message: errorMessage.slice(0, -1),
  };
};

export default reducerFromMap(reducerMap, initialState);
