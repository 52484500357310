// eslint jsx-a11y/anchor-is-valid: 0
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { userLogout } from '../actions/user.actions';

import SideMenu from './SideMenu';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1450 });
  }

  render() {
    const { name, userLogout, title, subtitle, children } = this.props;
    const { isDesktop } = this.state;
    return (
      <div
        className={`hold-transition skin-blue sidebar-mini ${
          isDesktop ? '' : 'sidebar-collapse'
        }`}
      >
        <div className="wrapper">
          <header className="main-header">
            <Link className="logo" to="/">
              <span className="logo-mini">
                <b>AT</b>
              </span>
              <span className="logo-lg">
                <b>AIRTUBE</b>
              </span>
            </Link>

            <nav className="navbar navbar-static-top">
              {/* <button className="sidebar-toggle" data-toggle="push-menu" >
            <span className="sr-only">Toggle navigation</span>
          </button>

          <form className="navbar-form navbar-left" role="search">
            <div className="form-group">
              <input type="text" className="form-control" id="navbar-search-input" placeholder="Search" />
            </div>
          </form> */}
              <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                  <li className="dropdown user user-menu">
                    <a className="dropdown-toggle" data-toggle="dropdown">
                      <img
                        src="/assets/images/user.png"
                        className="user-image"
                        alt="User"
                      />
                      <span className="hidden-xs">{name}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="user-header">
                        <p>{name}</p>
                      </li>
                      <li className="user-footer">
                        <div className="pull-right">
                          <button
                            type="button"
                            className="btn btn-default btn-flat"
                            onClick={userLogout}
                          >
                            Sign out
                          </button>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </header>

          <SideMenu userLogout={userLogout} />
          <div className="content-wrapper">
            {title ? (
              <section className="content-header">
                <h1>
                  {title}
                  {subtitle ? <small>{subtitle}</small> : ''}
                </h1>
              </section>
            ) : (
              ''
            )}
            <section className="content">{children}</section>
          </div>
        </div>
      </div>
    );
  }
}
Layout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  name: PropTypes.string,
  userLogout: PropTypes.func,
};

Layout.defaultProps = {
  title: '',
  subtitle: null,
  name: null,
  userLogout: () => {},
};

const mapStateToProps = state => ({
  name: state.user.name,
});

const mapActionToProps = { userLogout };

export default connect(
  mapStateToProps,
  mapActionToProps
)(Layout);
