/* eslint-disable jsx-a11y/label-has-for */
import React /* ,{ useState, useEffect } */ from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';

import { formatDateTime } from '../../libs/helpers';
import l from '../../libs/localization';
import TableCard from '../TableCard';

const PolicyTable = props => {
  const { data, visible, policyDelete, policyActivate } = props;
  let rows;
  if (data && data.length) {
    const maxDate = Math.max.apply(
      null,
      data.map(r => new Date(r.validFrom).getTime())
    );
    rows = data.map(r => (
      <tr
        key={r.code}
        title={r.statusMessage}
        className={
          maxDate === new Date(r.validFrom).getTime() ? 'highlight' : ''
        }
      >
        <td>{r.version}</td>
        <td>{r.url}</td>
        <td>{formatDateTime(r.createdAt)}</td>
        <td>{formatDateTime(r.validFrom)}</td>
        <td>{r.userCount}</td>
        <td>
          <div className="notification-tooltip">
            {maxDate !== new Date(r.validFrom).getTime() && (
              <span className="notification-tooltip-text">
                {l('PRIVACY.MAKE_PRIMARY')}
              </span>
            )}
            <button
              type="button"
              className=" btn-outline btn-notification-action "
              onClick={() => policyActivate(r)}
              disabled={maxDate === new Date(r.validFrom).getTime()}
            >
              <span className="glyphicon glyphicon-tag" />
            </button>
          </div>
          <div className="notification-tooltip">
            {r.userCount === 0 && (
              <span className="notification-tooltip-text">
                {l('COMMON.DELETE')}
              </span>
            )}

            <button
              type="button"
              className=" btn-outline btn-notification-action "
              onClick={() => policyDelete(r)}
              disabled={r.userCount !== 0}
            >
              <span className="glyphicon glyphicon-trash" />
            </button>
          </div>
        </td>
      </tr>
    ));
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <ReactCSSTransitionGroup transitionName="privacy-table-transition">
      {visible && (
        <TableCard>
          <tbody>
            <tr className="table-header">
              <th>{l('PRIVACY.CODE')}</th>
              <th>{l('PRIVACY.URL')}</th>
              <th>{l('PRIVACY.CREATED_AT')}</th>
              <th>{l('PRIVACY.VALID_FROM')}</th>
              <th>{l('PRIVACY.COUNT')}</th>
              <th />
            </tr>

            {rows}
          </tbody>
        </TableCard>
      )}
    </ReactCSSTransitionGroup>
  );
};

PolicyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  visible: PropTypes.bool.isRequired,
  policyDelete: PropTypes.func.isRequired,
  policyActivate: PropTypes.func.isRequired,
};

PolicyTable.defaultProps = {
  data: [],
};

export default PolicyTable;
