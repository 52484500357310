/* eslint-disable jsx-a11y/label-has-for */
import React /* ,{ useState, useEffect } */ from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import PropTypes from 'prop-types';

import l from '../../libs/localization';
import Notification from './Notification';
import TableCard from '../TableCard';

// const moment = require('moment');

const NotificationsTable = props => {
  const {
    data,
    visible,
    notificationDelete,
    notificationChangeStatus,
    toggleEditButton,
    filters,
  } = props;
  let rows;
  if (data && data.length) {
    rows = data.map(r => (
      <Notification
        key={r.id}
        r={r}
        notificationDelete={notificationDelete}
        notificationChangeStatus={notificationChangeStatus}
        modifyNotification={toggleEditButton}
        filters={filters}
      />
    ));
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }
  return (
    <ReactCSSTransitionGroup transitionName="notification-table-transition">
      {visible && (
        <TableCard>
          <tbody className="w-100">
            <tr className="table-header">
              <th>{l('NOTIFICATION.TEXT')}</th>

              <th>{l('NOTIFICATION.PLANNED_AT')}</th>
              <th>{l('NOTIFICATION.CREATED_AT')}</th>
              <th>{l('NOTIFICATION.LANGUAGE')}</th>
              <th>{l('NOTIFICATION.SENT')}</th>

              <th>{l('NOTIFICATION.STATUS')}</th>
              <th>{l('COMMON.EDIT')}</th>
              <th>{l('COMMON.DELETE')}</th>
            </tr>
            {rows}
          </tbody>
        </TableCard>
      )}
    </ReactCSSTransitionGroup>
  );
};

NotificationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  notificationDelete: PropTypes.func.isRequired,
  notificationChangeStatus: PropTypes.func.isRequired,
  toggleEditButton: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

NotificationsTable.defaultProps = {
  data: [],
  filters: {},
};

export default NotificationsTable;
