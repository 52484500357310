// import { USER_LOGOUT } from './user.actions';

export const API_ERROR = 'API_ERROR';
export const API_LOADING = 'API_LOADING';

export const callApi = ({
  apiCall,
  startAction = API_LOADING,
  successAction,
  errorAction = API_ERROR,
  processResult = null,
  processError = null,
  onStart = null,
}) => {
  return params => async (dispatch, getState) => {
    if (onStart && onStart(params, dispatch, getState) === false) return;
    dispatch({
      type: startAction,
      params,
    });
    try {
      let result = await apiCall(params, dispatch, getState);
      if (processResult) {
        const processedResult = processResult(
          result,
          params,
          dispatch,
          getState
        );
        if (processedResult !== undefined) {
          result = processedResult;
        }
      }
      dispatch({
        type: successAction,
        data: result,
      });
    } catch (error) {
      if (processError) processError(error, dispatch, getState);
      if (error.message === 'notAuthorized') {
        dispatch({ type: 'USER_LOGOUT' });
      }
      dispatch({
        type: errorAction,
        error: { name: error.name, message: error.message },
      });
    }
  };
};
