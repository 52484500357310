import { reducerFromMap } from './common.reducers';

import {
  NOTIFICATION_LIST,
  NOTIFICATION_LIST_COMPLETE,
  NOTIFICATION_LIST_ERROR,
  ROUTER_LOCATION_CHANGE,
  NOTIFICATION_CREATE,
  NOTIFICATION_CREATE_COMPLETE,
  NOTIFICATION_CREATE_ERROR,
  NOTIFICATION_DELETED,
  NOTIFICATION_LIST_CHANGE,
  NOTIFICATION_LIST_CHANGE_SUCCESS,
} from '../actions';

const moment = require('moment');

const initialState = {
  loading: false,
  search: '',
  filters: {
    dateFrom: moment()
      .subtract(6, 'months')
      .format('YYYY-MM-DD'),
    dateTo: moment()
      .endOf('year')
      .format('YYYY-MM-DD'),
  },
  data: null,
  message: null,
  filterData: null,
  filterDataLoading: false,
  filterDataMessage: null,
};

const reducerMap = {};

reducerMap[ROUTER_LOCATION_CHANGE] = (state, action) => {
  if (action.payload.location.pathname !== '/notifications') return state;
  return {
    ...state,
    filters: {
      dateFrom: moment
        .utc()
        .subtract(6, 'months')
        .format('YYYY-MM-DD'),
      dateTo: moment
        .utc()
        .endOf('year')
        .format('YYYY-MM-DD'),
    },
    message: null,
  };
};

reducerMap[NOTIFICATION_LIST] = state => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[NOTIFICATION_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data.data,
  filters: action.data.filters,
});
reducerMap[NOTIFICATION_CREATE] = (state, action) => ({
  ...state,
  message: null,
  loading: true,
});
reducerMap[NOTIFICATION_CREATE_ERROR] = (state, action) => ({
  ...state,
  message: null,
  loading: true,
});
reducerMap[NOTIFICATION_CREATE_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  // data: [action.data, ...state.data],
});
reducerMap[NOTIFICATION_CREATE_ERROR] = (state, action) => {
  console.log(
    '+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++NOTIFICATION_LIST_ERROR'
  );
  let errorMessage = '';
  debugger;
  if (action.errors)
    errorMessage += action.errors.map(error => `${error.message}\n`);
  if (action.error && action.error.message)
    errorMessage += `UNHANDLED: ${action.error.message}`;
  return {
    ...state,
    loading: false,
    message: errorMessage.slice(0, -1),
  };
};
reducerMap[NOTIFICATION_LIST_CHANGE] = (state, action) => ({
  ...state,
  loading: true,
});
reducerMap[NOTIFICATION_LIST_CHANGE_SUCCESS] = (state, action) => ({
  ...state,
  loading: false,
});
reducerMap[NOTIFICATION_DELETED] = (state, action) => ({
  ...state,
  loading: false,
  data: [action.data, ...state.data],
});
reducerMap[NOTIFICATION_LIST_ERROR] = (state, action) => {
  let errorMessage = '';
  if (action.errors)
    errorMessage += action.errors.map(error => `${error.message}\n`);
  if (action.error && action.error.message)
    errorMessage += `UNHANDLED: ${action.error.message}`;
  return {
    ...state,
    loading: false,
    message: errorMessage.slice(0, -1),
  };
};

export default reducerFromMap(reducerMap, initialState);
