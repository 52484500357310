import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';

const TableCard = ({ header, footer, children }) => {
  return (
    <Card
      header={header}
      footer={footer}
      bodyClassName="no-padding table-responsive"
    >
      <table className="table table-hover table-striped table-condensed">
        {children}
      </table>
    </Card>
  );
};

TableCard.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
};

TableCard.defaultProps = {
  header: null,
  children: null,
};

export default TableCard;
