import { callApi } from './commons.actions';
import { listUsersApi, deleteUserApi, editUserApi } from '../api/users.api';

// actions
export const USERS_LIST = 'USERS_LIST';
export const USERS_LIST_COMPLETE = 'USERS_LIST_COMPLETE';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';
export const USER_CREATED = 'USER_CREATED';
export const USER_DELETED = 'USER_DELETED';
export const USERS_FILTER = 'USERS_FILTER';
export const USERS_CHANGE_PAGE = 'USERS_CHANGE_PAGE';

export const usersListAction = callApi({
  apiCall: (p = {}) => {
    return listUsersApi(p);
  },
  startAction: USERS_LIST,
  successAction: USERS_LIST_COMPLETE,
  errorAction: USERS_LIST_ERROR,
});
export const usersSearchAction = (p = {}) => dispatch => {
  dispatch({ type: USERS_FILTER, data: p });
};

export const usersChangePageAction = (p = 0) => dispatch => {
  dispatch({ type: USERS_CHANGE_PAGE, data: p });
};

export const userCreateAction = callApi({
  apiCall: (p = {}) => {
    return editUserApi(p);
  },
  startAction: USERS_LIST,
  successAction: USER_CREATED,
  errorAction: USERS_LIST_ERROR,
});

export const userDeleteAction = callApi({
  apiCall: (p = {}) => {
    return deleteUserApi(p);
  },
  startAction: USERS_LIST,
  successAction: USERS_LIST_COMPLETE,
  errorAction: USERS_LIST_ERROR,
});

export const userEditAction = callApi({
  apiCall: (p = {}) => {
    return editUserApi(p);
  },
  startAction: USERS_LIST,
  successAction: USERS_LIST_COMPLETE,
  errorAction: USERS_LIST_ERROR,
});
export const userStatusChangeAction = callApi({
  apiCall: (p = {}) => {
    return editUserApi(p);
  },
  startAction: USERS_LIST,
  successAction: USERS_LIST_COMPLETE,
  errorAction: USERS_LIST_ERROR,
});
