import { http } from './commons.api';

// import log from '../libs/logger';

export const listShopApi = async params => {
  const response = await http.get('/api/v1/shops', params);
  return response;
};

export const loadDataLayoutApi = async params => {
  const response = await http.get('/api/v1/structureShop', params);
  return response;
};

export const oneShopApi = async ({ id }) => {
  const response = await http.get(`/api/v1/shops/${id}`);
  return response;
};
