import { callApi } from './commons.actions';
import {
  listNotificationsApi,
  createNotificationsApi,
  deleteNotificationsApi,
  changeNotificationStatusApi,
  editNotificationApi,
} from '../api/notifications.api';

// actions
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_LIST_COMPLETE = 'NOTIFICATION_LIST_COMPLETE';
export const NOTIFICATION_LIST_ERROR = 'NOTIFICATION_LIST_ERROR';
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_CREATE_COMPLETE = 'NOTIFICATION_CREATE_COMPLETE';
export const NOTIFICATION_CREATE_ERROR = 'NOTIFICATION_CREATE_ERROR';
export const NOTIFICATION_LIST_CHANGE = 'NOTIFICATION_LIST_CHANGE';
export const NOTIFICATION_LIST_CHANGE_SUCCESS =
  'NOTIFICATION_LIST_CHANGE_SUCCESS';
export const NOTIFICATION_DELETED = 'NOTIFICATION_DELETED';

export const notificationListAction = callApi({
  apiCall: (p = {}) => listNotificationsApi(p),
  startAction: NOTIFICATION_LIST,
  successAction: NOTIFICATION_LIST_COMPLETE,
  errorAction: NOTIFICATION_LIST_ERROR,
});
export const notificationSearchAction = (p = {}) => dispatch => {
  dispatch(notificationListAction(p));
};
export const notificationCreateAction = callApi({
  apiCall: (p = {}) => {
    return createNotificationsApi(p);
  },
  startAction: NOTIFICATION_CREATE,
  successAction: NOTIFICATION_CREATE_COMPLETE,
  errorAction: NOTIFICATION_CREATE_ERROR,
  processResult: (result, params, dispatch) =>
    result.errors
      ? dispatch({ type: NOTIFICATION_CREATE_ERROR, ...result, ...params })
      : dispatch(notificationListAction()),
});

export const notificationDeleteAction = callApi({
  apiCall: (p = {}) => {
    return deleteNotificationsApi(p);
  },
  startAction: NOTIFICATION_LIST,
  successAction: NOTIFICATION_LIST_COMPLETE,
  errorAction: NOTIFICATION_LIST_ERROR,
});

export const notificationStatusChangeAction = callApi({
  apiCall: (p = {}) => {
    return changeNotificationStatusApi(p);
  },
  startAction: NOTIFICATION_LIST,
  successAction: NOTIFICATION_LIST_COMPLETE,
  errorAction: NOTIFICATION_LIST_ERROR,
});
export const notificationEditAction = callApi({
  apiCall: (p = {}) => {
    return editNotificationApi(p);
  },
  startAction: NOTIFICATION_LIST,
  successAction: NOTIFICATION_LIST_COMPLETE,
  // startAction: NOTIFICATION_LIST_CHANGE,
  // successAction: NOTIFICATION_LIST_CHANGE_SUCCESS,
  errorAction: NOTIFICATION_LIST_ERROR,
  // processResult: (result, params, dispatch) =>
  //   dispatch(notificationListAction()),
});
