/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import PropTypes from 'prop-types';

import l from '../../libs/localization';

// const moment = require('moment');

const ErrorToast = props => {
  const { message, eraseMessage } = props;
  const [active, setActive] = useState(!!message);
  useEffect(() => {
    if (message !== undefined && message !== null && message !== '')
      setActive(true);
    else setActive(false);
  }, [message]);
  return (
    <ReactCSSTransitionGroup transitionName="notification-error-transition">
      {active && (
        <div className="absolute-error-outer-container">
          <div className="absolute-error-inner-container">
            <span className="glyphicon glyphicon-exclamation-sign main-icon" />
            <div className="absolute-error-content-container">
              <p className="absolute-error-title">{l('COMMON.ERROR')}</p>
              <p className="absolute-error-content">{message}</p>
            </div>
            <button
              type="button"
              className="btn btn-danger m-0 exit-icon"
              onClick={() => eraseMessage()}
            >
              <span className="glyphicon glyphicon-remove" />
              <span>{l('COMMON.DISMISS')}</span>
            </button>
          </div>
        </div>
      )}
    </ReactCSSTransitionGroup>
  );
};

ErrorToast.propTypes = {
  message: PropTypes.string,
  eraseMessage: PropTypes.func.isRequired,
};

ErrorToast.defaultProps = {
  message: null,
};

export default ErrorToast;
