import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getService } from '../libs/helpers';

const SideMenu = props => {
  const routes = getService('routes');
  const { userLogout } = props;
  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <ul className="sidebar-menu" data-widget="tree">
          {routes
            .filter(r => r.showInMenu)
            .map(r => (
              <li key={r.label} className="active">
                <Link to={r.path}>
                  <i className={r.iconClass} />
                  <span>{r.label}</span>
                </Link>
              </li>
            ))}
          <li key="logout" className="active">
            <Link to="/" onClick={userLogout}>
              <i className="glyphicon glyphicon-off" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </aside>
  );
};

SideMenu.propTypes = {
  userLogout: PropTypes.func.isRequired,
};

export default SideMenu;
