import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import l from '../../libs/localization';

import Page from './Page';
import Card from '../Card';

class ModelDetail extends React.Component {
  state = {
    currentPage: null,
    showAll: false,
  };

  changePage(page) {
    debugger;
    let [currentPage] = page.split('@@');

    if (currentPage === 'all') currentPage = null;

    this.setState({
      currentPage,
    });
    console.log('model details', ModelDetail);
  }

  showAll(showAll) {
    debugger;
    this.setState({ showAll: showAll === 'true' });
  }

  render() {
    const { model } = this.props;

    const { currentPage, showAll } = this.state;

    const effectiveShowAll = showAll;

    const { metadata } = model;

    return (
      <div className="">
        <Card
          className="summary box-primary"
          footer={
            <div className="form-group m-0">
              <label className="col-xs-1 text-right ">
                {l('COMMON.SECTION')}:{' '}
              </label>
              <div className="col-xs-4">
                <select
                  className="form-control input-sm"
                  onChange={page => this.changePage(page.target.value)}
                >
                  <option value="all">{l('COMMON.SUMMARY')}</option>
                  {metadata.pages.map(p => (
                    <option key={p.title} value={p.title}>
                      {p.title}
                    </option>
                  ))}
                </select>
              </div>
              <label key="1" className="col-xs-3 text-right">
                {l('COMMON.SHOW_FIELDS')}:{' '}
              </label>
              <div key="2" className="col-xs-3">
                <select
                  className="form-control input-sm"
                  onChange={page => this.showAll(page.target.value)}
                  value={this.state.showAll}
                >
                  <option value={true}>{l('COMMON.DATA_ALL')}</option>
                  <option value={false}>{l('COMMON.DATA_ONLY_FILLED')}</option>
                </select>
              </div>
            </div>
          }
        >
          <div className="pull-left" />
          <div className="pull-right text-right">
            <label>{l('COMMON.LAST_UPDATE')}:</label>{' '}
            {moment(model.modifiedAt).format('DD/MM/YYYY - hh:mm')}
          </div>
        </Card>

        <div className="project-data">
          {metadata.pages
            .filter(
              page =>
                (currentPage === null && (effectiveShowAll || page.answers)) ||
                page.title === currentPage
            )
            .map((page, idx) => (
              <Page
                key={idx}
                dataLayout={page}
                model={model}
                showAll={showAll}
              />
            ))}
        </div>
      </div>
    );
  }
}

ModelDetail.propTypes = {
  model: PropTypes.object.isRequired,
  showAll: PropTypes.bool,
};

ModelDetail.defaultProps = {
  showAll: true,
};

export default ModelDetail;
