import { callApi } from './commons.actions';
import { listImportsApi } from '../api';

export const FILESIMPORT_LIST = 'FILESIMPORT_LIST';
export const FILESIMPORT_LIST_COMPLETE = 'FILESIMPORT_LIST_COMPLETE';
export const FILESIMPORT_LIST_ERROR = 'FILESIMPORT_LIST_ERROR';

export const FILESIMPORT_IMPORT_COMPLETE = 'FILESIMPORT_IMPORT_COMPLETE';
export const FILESIMPORT_IMPORT_ERROR = 'FILESIMPORT_IMPORT_ERROR';

export const filesImportListLoadAction = callApi({
  apiCall: listImportsApi,
  startAction: FILESIMPORT_LIST,
  successAction: FILESIMPORT_LIST_COMPLETE,
  errorAction: FILESIMPORT_LIST_ERROR,
});

export const fileImportCompleteAction = callApi({
  apiCall: listImportsApi,
  startAction: FILESIMPORT_LIST,
  successAction: FILESIMPORT_LIST_COMPLETE,
  errorAction: FILESIMPORT_LIST_ERROR,
});

export const fileImportErrorAction = e => ({
  type: FILESIMPORT_IMPORT_ERROR,
  error: e,
});
