import { reducerFromMap } from './common.reducers';
import { parseUrlParameters, parseArrayParameterValue } from '../libs/helpers';

import {
  FILESIMPORT_LIST,
  FILESIMPORT_LIST_COMPLETE,
  FILESIMPORT_LIST_ERROR,
  FILESIMPORT_IMPORT_ERROR,
} from '../actions';

const initialState = {
  loading: false,
  data: null,
  message: null,
};

const reducerMap = {};

reducerMap[FILESIMPORT_LIST] = (state, action) => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[FILESIMPORT_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data,
});

reducerMap[FILESIMPORT_LIST_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error.message,
});

reducerMap[FILESIMPORT_IMPORT_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error,
});
export default reducerFromMap(reducerMap, initialState);
