import { reducerFromMap } from './common.reducers';

import {
  SHOP_LOAD,
  SHOP_LOAD_COMPLETE,
  SHOP_LOAD_ERROR,
  DATALAYOUT_SHOP_LOAD,
  DATALAYOUT_SHOP_LOAD_COMPLETE,
  DATALAYOUT_SHOP_LOAD_ERROR,
} from '../actions';
import {
  digestCompressorData,
  normalizeTreeStructure,
} from '../libs/dataUtils';

const initialState = {
  loading: false,
  message: null,

  id: null,
  data: null,

  dataLayout: null,
  dataLayoutLoading: false,

  mode: 'read',

  projectUpdates: {},
  structureUpdates: [],

  confirmRequest: false,

  ready: false,
};

const reducerMap = {};

reducerMap[SHOP_LOAD] = (state, action) => ({
  ...state,
  loading: true,
  ready: false,
  message: null,
  data: null,
});

reducerMap[SHOP_LOAD_COMPLETE] = (state, action) => {
  if (state.dataLayout) {
    digestCompressorData(action.data, state.dataLayout.tree);
  }

  return {
    ...state,
    loading: false,
    ready: !state.dataLayoutLoading,
    message: null,
    mode: 'read',
    data: action.data,
  };
};

reducerMap[SHOP_LOAD_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  ready: false,
  message: action.error.message,
});

reducerMap[DATALAYOUT_SHOP_LOAD] = (state, action) => ({
  ...state,
  dataLayoutLoading: true,
  ready: false,
  message: null,
  dataLayout: null,
});

reducerMap[DATALAYOUT_SHOP_LOAD_COMPLETE] = (state, action) => {
  normalizeTreeStructure(action.data.tree);
  let { data, loading } = state;

  if (data) {
    digestCompressorData(data, action.data.tree);
    data = { ...data };
  }

  return {
    ...state,
    id: action.data.id,
    dataLayoutLoading: false,
    ready: !loading && !!data,
    message: null,
    dataLayout: action.data,
    data,
  };
};

reducerMap[DATALAYOUT_SHOP_LOAD_ERROR] = (state, action) => ({
  ...state,
  dataLayoutLoading: false,
  message: action.error.message,
});

export default reducerFromMap(reducerMap, initialState);
