import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from './history';

import rootReducer from '../reducers';
import appMiddlewares from '../middlewares';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  // const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    ...appMiddlewares,
    routerMiddleware(history),
    thunk,
    logger,
  ];

  const store = createStore(
    connectRouter(history)(rootReducer),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept('../reducers/', () => {
      const updatedRootReducer = require('../reducers').default;
      store.replaceReducer(updatedRootReducer);
    });
  }

  store.dispatch({ type: '@@APP_START' });

  return store;
};

export default configureStore;

export { history };
