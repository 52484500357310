import { reducerFromMap } from './common.reducers';

import {
  PRIVACY_LIST,
  PRIVACY_LIST_COMPLETE,
  PRIVACY_LIST_ERROR,
  PRIVACY_ERROR,
} from '../actions';

const initialState = {
  loading: false,
  data: null,
  message: null,
};

const reducerMap = {};

reducerMap[PRIVACY_LIST] = state => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[PRIVACY_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data,
});

reducerMap[PRIVACY_LIST_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error.message,
});

reducerMap[PRIVACY_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error,
});
export default reducerFromMap(reducerMap, initialState);
