import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { shopListAction, shopSetFilterAction } from '../actions';

import { formatDateTime } from '../libs/helpers';
import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import ShopListFilter from '../components/shops/ShopListFilter';

const ShopListPage = ({
  data,
  search,
  loading,
  message,
  filters,
  filterData,
  shopList,
  shopSetFilter,
}) => {
  useEffect(() => {
    shopList(search);
  }, [search]);
  let rows;
  let count;
  try {
    count = data.count ? data.count : 0;
    data = data.list ? data.list : null;
  } catch (e) {
    count = 0;
    data = null;
  }

  console.log('Data: ', data);

  if (data && data.length) {
    rows = data.map(r => (
      <tr key={r._id}>
        <td>
          <Link to={`/shops/${r._id}`}>{r.shopname}</Link>
        </td>
        {/* <td>{r.shopName}</td> */}
        <td>{r.brand ? r.brand : '-'}</td>
        <td>{r.countryname ? r.countryname : '-'}</td>
        <td>{r.countrycode ? r.countrycode : '-'}</td>
        <td>{r.icon ? r.icon : '-'}</td>
        <td>{r.urlparts ? r.urlparts : '-'}</td>
        <td>{r.urlhome ? r.urlhome : '-'}</td>
        <td>{r.urllubricants ? r.urllubricants : '-'}</td>
        <td>{r.urltools ? r.urltools : '-'}</td>
        <td>{r.urlcompressors ? r.urlcompressors : '-'}</td>
        <td>{r.urlairinstallations ? r.urlairinstallations : '-'}</td>
      </tr>
    ));
    count = data.length;
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <Layout title={l('SHOP.SHOP_LIST_TITLE')}>
      <TableCard
        header={
          <ShopListFilter
            filters={filters}
            onFilter={shopSetFilter}
            filterData={filterData}
          />
        }
      >
        <tbody>
          <tr className="table-header">
            <th>{l('SHOP.SHOP_NAME')}</th>
            <th>{l('SHOP.SHOP_BRAND')}</th>
            <th>{l('SHOP.SHOP_COUNTRY_NAME')}</th>
            <th>{l('SHOP.SHOP_COUNTRY_CODE')}</th>
            <th>{l('SHOP.SHOP_ICON')}</th>
            <th>{l('SHOP.SHOP_URL_PARTS')}</th>
            <th>{l('SHOP.SHOP_URL_HOME')}</th>
            <th>{l('SHOP.SHOP_URL_LUBRICANTS')}</th>
            <th>{l('SHOP.SHOP_URL_TOOLS')}</th>
            <th>{l('SHOP.SHOP_URL_COMPRESSORS')}</th>
            <th>{l('SHOP.SHOP_URL_AIR_INSTALLATIONS')}</th>
            {/* <th>{l('DEALER.DEALER_CONTACT_NUMBER')}</th> */}
          </tr>

          {rows}
          {/* <tr>
            <th>
              <button className="btn btn-secondary">
                <span className="glyphicon glyphicon-menu-left" />
                <b className="margin-left-10">{l('prev')}</b>
              </button></th>
            <th>
              <button className="btn btn-secondary">
                <b className="margin-left-10">{l('next')}</b>
                <span className="glyphicon glyphicon-menu-right" />
              </button></th>
          </tr> */}
        </tbody>
      </TableCard>
      {/* <Loader visible={loading} /> */}
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.shops,
});

const mapDispatchToProps = {
  shopList: shopListAction,
  shopSetFilter: shopSetFilterAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopListPage);
