import { push } from 'connected-react-router';
import { callApi } from './commons.actions';
import {
  listDealerApi,
  oneDealerApi,
  loadDataLayoutApi,
  listDealerNoLocationApi,
} from '../api/dealers.api';
import { encodeParameters, encodeLocationParamaters } from '../api/commons.api';

// actions
// export const DEALERS_SET_FILTER = 'DEALERS_SET_FILTER';
export const DEALERS_LIST = 'DEALERS_LIST';
export const DEALERS_LIST_COMPLETE = 'DEALERS_LIST_COMPLETE';
export const DEALERS_LIST_ERROR = 'DEALERS_LIST_ERROR';

export const DEALERS_LIST_NEXT = 'DEALERS_LIST_NEXT';
export const DEALERS_LIST_NEXT_COMPLETE = 'DEALERS_LIST_COMPLETE_NEXT';
export const DEALERS_LIST_NEXT_ERROR = 'DEALERS_LIST_ERROR_NEXT';

export const DEALERS_LIST_PREV = 'DEALERS_LIST_PREV';
export const DEALERS_LIST_PREV_COMPLETE = 'DEALERS_LIST_PREV_COMPLETE';
export const DEALERS_LIST_PREV_ERROR = 'DEALERS_LIST_PREV_ERROR';

export const DEALERS_LOAD_FILTERS = 'DEALERS_LOAD_FILTERS';
export const DEALERS_LOAD_FILTERS_COMPLETE = 'DEALERS_LOAD_FILTERS_COMPLETE';
export const DEALERS_LOAD_FILTERS_ERROR = 'DEALERS_LOAD_FILTERS_ERROR';

export const DEALER_LOAD = 'DEALER_LOAD';
export const DEALER_LOAD_COMPLETE = 'DEALER_LOAD_COMPLETE';
export const DEALER_LOAD_ERROR = 'DEALER_LOAD_ERROR';

export const DATALAYOUT_DEALER_LOAD = 'DATALAYOUT_DEALER_LOAD ';
export const DATALAYOUT_DEALER_LOAD_COMPLETE =
  'DATALAYOUT_DEALER_LOAD_COMPLETE';
export const DATALAYOUT_DEALER_LOAD_ERROR = 'DATALAYOUT_DEALER_LOAD_ERROR';

export const dealerListAction = callApi({
  apiCall: (p = {}) => listDealerApi(p),
  startAction: DEALERS_LIST,
  successAction: DEALERS_LIST_COMPLETE,
  errorAction: DEALERS_LIST_ERROR,
});

export const dealerListNoLocationAction = callApi({
  apiCall: (p = {}) => listDealerNoLocationApi(p),
  startAction: DEALERS_LIST,
  successAction: DEALERS_LIST_COMPLETE,
  errorAction: DEALERS_LIST_ERROR,
});

export const dealerNextListAction = callApi({
  apiCall: p => listDealerApi(p),
  startAction: DEALERS_LIST_NEXT,
  successAction: DEALERS_LIST_NEXT_COMPLETE,
  errorAction: DEALERS_LIST_NEXT_ERROR,
});

export const dealerSetFilterAction = (p = {}) => dispatch => {
  dispatch(push(encodeParameters(p, '/dealers')));
  dispatch(dealerListAction(p));
};

export const dealerSetFilterLocationAction = (p = {}) => dispatch => {
  dispatch(push(encodeLocationParamaters(p, '/dealers')));
  dispatch(dealerListNoLocationAction(p));
};

export const dataLayoutLoad = callApi({
  apiCall: loadDataLayoutApi,
  startAction: DATALAYOUT_DEALER_LOAD,
  successAction: DATALAYOUT_DEALER_LOAD_COMPLETE,
  errorAction: DATALAYOUT_DEALER_LOAD_ERROR,
});

export const dealerLoadAction = callApi({
  apiCall: oneDealerApi,
  startAction: DEALER_LOAD,
  successAction: DEALER_LOAD_COMPLETE,
  errorAction: DEALER_LOAD_ERROR,
  onStart: (params, dispatch, getState) => {
    const dealerState = getState().dealer;
    // if (!dealerState.dataLayout && !dealerState.dataLayoutLoading) {
    //   dispatch(dataLayoutLoad());
    // }
  },
  processResult: (result, params, dispatch, getState) => {
    console.log({ result });
  },
});
