import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { compressorListAction, compressorSetFilterAction } from '../actions';

import { formatDateTime } from '../libs/helpers';
import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import CompressorListFilter from '../components/compressors/CompressorListFilter';

const CompressorListPage = ({
  data,
  search,
  loading,
  message,
  filters,
  filterData,
  compressorList,
  compressorSetFilter,
}) => {
  useEffect(() => {
    debugger;
    compressorList(search);
  }, [search]);

  let count = 0;
  let rows;
  console.log('Data: ', data);
  if (data && data.length) {
    rows = data.map(r => (
      <tr key={r._id}>
        <td>
          <Link to={`/compressors/${r._id}`}>{r._id}</Link>
        </td>
        <td>{r.data.brand}</td>
        <td>{r.data.commercialDescription}</td>
        <td>{formatDateTime(r.lastImportAt)}</td>
      </tr>
    ));
    count = data.length;
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <Layout title={l('COMPRESSOR.MODEL_LIST_TITLE')}>
      <TableCard
        header={
          <CompressorListFilter
            filters={filters}
            onFilter={compressorSetFilter}
            filterData={filterData}
          />
        }
      >
        <tbody>
          <tr className="table-header">
            <th>{l('COMPRESSOR.PARTNUMBER')}</th>
            <th>{l('COMMON.BRAND')}</th>
            <th>{l('COMMON.DESCRIPTION')}</th>
            <th>{l('COMMON.LAST_UPDATE')}</th>
          </tr>

          {rows}
        </tbody>
      </TableCard>

      <Loader visible={loading} />
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.compressors,
});

const mapDispatchToProps = {
  compressorList: compressorListAction,
  compressorSetFilter: compressorSetFilterAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompressorListPage);
