import React from 'react';
import log from '../libs/logger';

const Button = ({ onClick, text, obj }) => {
  return (
    <button
      className="table-button"
      onClick={
        typeof onClick === 'function' ? onClick : () => log.debug('No function')
      }
    >
      <b className="">{text}</b>
    </button>
  );
};

export default Button;
