import { getConfig } from '../config';

const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
// https://accounts.google.com/o/oauth2/v2/auth?client_id=xxx&redirect_uri=http%3A%2f%2flocalhost:3022%2flogin&response_type=id_token%20token&scope=openid%20profile%20email&state=xxx&nonce=sdasd

const authParams = {
  client_id: getConfig('googleKey'),
  redirect_uri: `${getConfig('rootUri')}/login`,
  response_type: 'id_token token',
  scope: 'openid profile email',
  state: 'xxxx',
  nonce: 'xxxx',
};

export const getGoogleLoginUri = () => {
  let url = `${googleAuthUrl}?`;
  Object.keys(authParams).forEach(
    k => (url += `${k}=${encodeURIComponent(authParams[k])}&`)
  );
  return url;
};
