import { reducerFromMap } from './common.reducers';
import { parseUrlParameters, parseArrayParameterValue } from '../libs/helpers';

import {
  DEALERS_LIST,
  DEALERS_LIST_COMPLETE,
  DEALERS_LIST_ERROR,
  ROUTER_LOCATION_CHANGE,
} from '../actions';

const initialState = {
  loading: false,
  search: '',
  filters: {},
  data: null,
  message: null,
  filterData: null,
  filterDataLoading: false,
  filterDataMessage: null,
};

const reducerMap = {};

const basicSearchFilters = ['search', 'approved', 'active'];

reducerMap[ROUTER_LOCATION_CHANGE] = (state, action) => {
  console.log(action.payload.location);
  console.log(action);
  if (action.payload.location.pathname !== '/dealers') return state;

  const params = parseUrlParameters(action.payload.location.search);

  const { search } = params;

  let filters = { search };

  Object.keys(params).forEach(key => {
    if (basicSearchFilters.indexOf(key) >= 0) return;
    const val = parseArrayParameterValue(params[key]);
    filters[key] = val;
  });

  return {
    ...state,
    filters,
    message: null,
  };
};

reducerMap[DEALERS_LIST] = (state, action) => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[DEALERS_LIST_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  data: action.data,
});

reducerMap[DEALERS_LIST_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message: action.error.message,
});

export default reducerFromMap(reducerMap, initialState);
