import React from 'react';
import _ from 'lodash';
import format from 'string-template';
import moment from 'moment';
import userLocale from 'get-user-locale';
import ReactMarkdown from 'react-markdown';
//import LocalizedString from 'react-localization';

import * as locales from '../locales';
import log from './logger';

import { forceLocale, logger } from '../config';

// Loads locale on refresh
// If forceLocale is defined in config it is used and overwrites user's selection
let locale =
  forceLocale ||
  (Array.isArray(userLocale()) ? userLocale()[0] : userLocale()) ||
  'en';

log.debug('Selected locale', locale);

/**
 * Custom localization library
 *
 * @author davide.mantovani
 * @author michele.forchione
 *
 * @param {String|Date} search Can be the translation key or a Date
 * @param {Array|Object} params If the localized string is a string template, pass an array or an object with keys (default: null)
 * @param {Boolean} markdown If it is needed to use Markdown interpreter - generated HTML code (default: false)
 */
const localize = (search, params = null, markdown = false) => {
  // Reloading changes to user locale
  if (new RegExp('^[a-z]{2}(-|_)[A-Z]{2}$').test(locale)) {
    locale = locale.substring(0, 2);
  } else if (!new RegExp('^[a-z]{2}$').test(locale)) {
    // Fallback
    locale = 'en';
  }

  try {
    if (locale === 'en') {
      require(`moment/locale/${locale}-gb`);
    } else {
      require(`moment/locale/${locale}`);
    }
  } catch (e) {
    log.error(`MOMENT.JS: Locale ${locale} not found`);
  }

  // If localize(Date, 'format')
  // Lib used https://www.npmjs.com/package/moment
  if (search instanceof Date && typeof params === 'string') {
    moment.locale(locale);
    // Return the formatted date
    return moment(search.getTime()).format(params);
  }

  // If default text
  let str = _.get(locales.default, `${locale}.${search}`);

  if (!logger && (!str || str === '')) {
    str = _.get(locales.default, `en.${search}`);
  }

  if (logger && (!str || str === '')) {
    // If the logging is enabled in config, it enables the random generation of strings to highlight missing values
    // TODO add MAP as a string cache
    str = _.get(locales.default, `en.${search}`);
    str = str
      ? str
          .split('')
          .sort(() => (Math.random() > 0.5 ? -1 : 1))
          .join('')
      : null;
    str = <span>{str || search}</span>;
  } else {
    // Check if it is needed to complete the {0} with {1}, ['sentence', 'params']
    // Support both array and object
    // Lib used https://www.npmjs.com/package/string-template
    if (Array.isArray(params) || params instanceof Object) {
      str = format(str, params);
    }

    // Check if it is _needed_ to interpret `markdown`
    // Supports all commands (even links!!1!!111!!) (see eg. https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet)
    // Lib used https://www.npmjs.com/package/react-markdown
    if (markdown) {
      str = <ReactMarkdown source={str} />;
    }
  }

  //  Return the resulted string
  return str;
};

export const setLocale = loc => {
  locale = loc;
};

export const getLocale = () => {
  return locale;
};

// This library supports also recoursive use, eg.:
//
// DATA
//// {
////   "TEST": {
////     "LABEL": "# Label {0} _tested_ on {1}"
////   }
//// }
//
// CODE
//// localize(
////   'TEST.LABEL',
////   ['test', localize(new Date(), 'DD MMM YYYY hh:mm')],
////   true
//// );

export default localize;
