/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { filesImportListLoadAction, fileImportErrorAction } from '../actions';

import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import FileImportRow from '../components/fileImport/FileImportRow';
import Upload from '../components/fileImport/upload/Upload';

const FilesImportPage = ({
  data,
  loading,
  filesImportList,
  message,
  importComplete,
  importError,
}) => {
  useEffect(() => {
    debugger;
    filesImportList();
  }, [filesImportList]);
  let rows;
  if (data && data.length) {
    console.log('data', data);
    rows = data.map(r => <FileImportRow key={r.id} r={r} />);
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }
  return (
    <Layout title={l('FILES_IMPORT.PAGE_TITLE')}>
      {message && <span className="w-100 c-black">{message}</span>}
      <div className="notifications-container">
        <div className="row padding-bottom">
          <div className="col-sm-4">
            <div className="card-shadow bg-white import-box">
              <Upload
                title={l('FILES_IMPORT.MASTERDATA')}
                target="masterdata"
                onComplete={importComplete}
                onError={importError}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card-shadow bg-white import-box">
              <Upload
                title={l('FILES_IMPORT.ASSOCIATIONS')}
                target="ean"
                onComplete={importComplete}
                onError={importError}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card-shadow bg-white import-box">
              <Upload
                title={l('FILES_IMPORT.DEALER_SHOP')}
                target="dealers"
                onComplete={importComplete}
                onError={importError}
              />
            </div>
          </div>
        </div>
        <TableCard>
          <tbody>
            <tr className="table-header">
              <th>{l('FILES_IMPORT.TYPE')}</th>
              <th>{l('FILES_IMPORT.NAME')}</th>
              <th>{l('FILES_IMPORT.STATUS')}</th>
              <th>{l('FILES_IMPORT.CREATED_AT')}</th>
              <th>{l('FILES_IMPORT.PROCESSED_AT')}</th>
            </tr>

            {rows}
          </tbody>
        </TableCard>
      </div>
      <Loader visible={loading} />
    </Layout>
  );
};

FilesImportPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  message: PropTypes.string,
  filters: PropTypes.shape({
    dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    language: PropTypes.string,
  }),
  filesImportList: PropTypes.func.isRequired,
  importComplete: PropTypes.func.isRequired,
  importError: PropTypes.func.isRequired,
};

FilesImportPage.defaultProps = {
  data: [],
  loading: true,
  message: null,
  filters: {},
};

const mapStateToProps = state => ({
  ...state.filesImports,
});

export default connect(
  mapStateToProps,
  {
    importError: e => fileImportErrorAction(e),
    filesImportList: filesImportListLoadAction,
    importComplete: filesImportListLoadAction,
  }
)(FilesImportPage);
