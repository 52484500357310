/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import l from '../../libs/localization';
import Form from '../Form';

const CreatePolicyForm = props => {
  const { onCreate, visible, closingCB } = props;
  const formComponent = (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">{l('PRIVACY.CREATE')}</h3>
      </div>
      {/* <!-- /.box-header --> */}
      {/* <!-- form start --> */}
      <Form onSubmit={onCreate} className="w-100" role="form">
        {({ controlInput, values }) => (
          <div className="box-body">
            <div className="form-group">
              <label htmlFor="privacyCode">{l('PRIVACY.CODE')}</label>
              <input
                id="privacyCode"
                type="text"
                className="form-control notification-text"
                placeholder={l('PRIVACY.CODE')}
                {...controlInput('version')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="privacyUrl">{l('PRIVACY.URL')}</label>
              <input
                id="privacyUrl"
                type="text"
                className="form-control notification-text"
                placeholder={l('PRIVACY.URL')}
                {...controlInput('url')}
              />
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger m-0"
                onClick={closingCB}
              >
                <span className="glyphicon glyphicon-eject" />
                <b className="margin-left-10">{l('COMMON.CLOSE')}</b>
              </button>
              <button
                type="submit"
                disabled={!values.url || !values.version}
                className={
                  values.url && values.version
                    ? 'btn btn-success m-0'
                    : 'btn btn-default m-0'
                }
              >
                <span className="glyphicon glyphicon-save" />
                <b className="margin-left-10">{l('PRIVACY.SAVE')}</b>
              </button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
  return (
    <ReactCSSTransitionGroup transitionName="privacy-form-transition">
      {visible && formComponent}
    </ReactCSSTransitionGroup>
  );
};

CreatePolicyForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  closingCB: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

CreatePolicyForm.defaultProps = {};

export default CreatePolicyForm;
