export const reducerFromMap = (map, initialState = null) => {
  return (...args) => {
    if (args.length > 0 && !args[0] && initialState) {
      args[0] = initialState;
    }
    const action = args[1];
    const reducer = map[action.type];
    let newState = args[0];
    if (reducer) newState = reducer(...args);

    return newState;
  };
};
