import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../libs/helpers';
import l from '../../libs/localization';

const moment = require('moment');

const StatusSwitcher = (onChange, status, id, filters) => {
  if (status !== 'draft' && status !== 'planned') {
    return (
      <span
        className={
          (status === 'error' ? 'notif-status-error ' : '') +
          (status === 'sent' ? 'notif-status-sent ' : '')
        }
      >
        {status}
      </span>
    );
  }
  return (
    <select
      value={status}
      onChange={e =>
        onChange({
          data: { status: e.target.value, id },
          filters,
        })
      }
    >
      <option value="draft">Draft</option>
      <option value="planned">Planned</option>
    </select>
  );
};
class Notification extends React.Component {
  constructor(props) {
    super(props);
    const { r } = this.props;
    this.state = {
      r,
    };
    this.tablerefs = {
      text: React.createRef(),
      plannedSendAt: React.createRef(),
    };
    this.openModifyForm = this.openModifyForm.bind(this);
  }

  componentWillReceiveProps(newprops) {
    if (newprops.r) {
      this.setState({ r: newprops.r });
    }
  }

  openModifyForm() {
    const { r: data } = this.state;
    const { modifyNotification, filters } = this.props;

    if (modifyNotification) {
      modifyNotification({ data, filters });
    }
  }

  render() {
    const {
      filters,
      notificationDelete,
      notificationChangeStatus,
      r: rprop,
    } = this.props;
    const { r } = this.state;
    return (
      <tr key={r.id} title={r.statusMessage}>
        <td className="notification-text">{r.text}</td>
        <td>
          {formatDateTime(moment.utc(r.plannedSendAt || rprop.plannedSendAt))}
        </td>
        <td>{formatDateTime(moment.utc(r.createdAt))}</td>
        <td>{l(`LANGS.${r.language}`)}</td>
        <td>{r.sentAt ? l('COMMON.YES') : l('COMMON.NO')}</td>
        <td>
          {StatusSwitcher(notificationChangeStatus, r.status, r.id, filters)}
        </td>
        <td>
          <div className="notification-tooltip">
            {r.status !== 'error' && r.status !== 'sent' && (
              <span className="notification-tooltip-text">
                {l('NOTIFICATION.EDIT-TOOLTIP')}
              </span>
            )}
            <button
              type="button"
              className=" btn-outline btn-notification-action"
              onClick={this.openModifyForm}
              disabled={r.status === 'error' || r.status === 'sent'}
            >
              <span className="glyphicon glyphicon-pencil" />
            </button>
          </div>
        </td>
        <td>
          <div className="notification-tooltip">
            {r.status !== 'error' && r.status !== 'sent' && (
              <span className="notification-tooltip-text">
                {l('NOTIFICATION.DELETE-TOOLTIP')}
              </span>
            )}
            <button
              type="button"
              className=" btn-outline btn-notification-action"
              onClick={notificationDelete.bind(this, { id: r.id, filters })}
              disabled={r.status === 'error' || r.status === 'sent'}
            >
              <span className="glyphicon glyphicon-trash" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
}
Notification.propTypes = {
  r: PropTypes.shape({
    id: PropTypes.string,
    statusMessage: PropTypes.string,
    text: PropTypes.string,
    plannedSendAt: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  filters: PropTypes.shape({
    dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    language: PropTypes.string,
  }),
  modifyNotification: PropTypes.func.isRequired,
  notificationDelete: PropTypes.func.isRequired,
  notificationChangeStatus: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  filters: {},
};

export default Notification;
