import React from 'react';
import PropTypes from 'prop-types';

import l from '../../libs/localization';

import Form from '../Form';

const NotificationsMenu = ({ filters, onFilter }) => {
  return (
    <Form
      className="d-flex noti-menu"
      onSubmit={onFilter}
      initialValue={filters}
    >
      {({ controlInput }) => (
        <>
          <div>
            <span className="c-black margin-r-5">
              {l('NOTIFICATION.SEARCH_FROM')}
            </span>
            <div className="input-group">
              <input
                type="date"
                className="form-control date-input"
                placeholder={l('NOTIFICATION.SEARCH_FROM')}
                {...controlInput('dateFrom')}
              />
            </div>
          </div>
          <div>
            <span className="c-black margin-left-10 margin-r-5">
              {l('NOTIFICATION.SEARCH_TO')}
            </span>
            <div className="input-group">
              <input
                type="date"
                className="form-control  date-input"
                placeholder={l('NOTIFICATION.SEARCH_TO')}
                {...controlInput('dateTo')}
              />
            </div>
          </div>
          <div>
            <span className="c-black margin-left-10 margin-r-5">
              {`${l('NOTIFICATION.LANGUAGE')}:`}
            </span>
            <select
              type="select"
              className="form-control noti-select"
              placeholder={l('NOTIFICATION.LANGUAGE')}
              {...controlInput('language')}
            >
              <option value={null}>ANY</option>
              <option value="en">{l(`LANGS.en`)}</option>
              <option value="it">{l(`LANGS.it`)}</option>
              <option value="de">{l(`LANGS.de`)}</option>
              <option value="fr">{l(`LANGS.fr`)}</option>
              <option value="es">{l(`LANGS.es`)}</option>
            </select>
          </div>
          <div>
            <span className="c-black margin-left-10 margin-r-5">
              {`${l('NOTIFICATION.STATUS')}:`}
            </span>
            <select
              type="select"
              className="form-control noti-select"
              placeholder={l('NOTIFICATION.STATUS')}
              {...controlInput('status')}
            >
              <option value="">ALL</option>
              <option value="draft">DRAFT</option>
              <option value="planned">PLANNED</option>
              <option value="sent">SENT</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary noti-menu-button">
            <span className="glyphicon glyphicon-filter" />
            <b className="margin-left-10">{l('NOTIFICATION.FILTER')}</b>
          </button>
        </>
      )}
    </Form>
  );
};
NotificationsMenu.propTypes = {
  filters: PropTypes.shape({
    dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    language: PropTypes.string,
  }),
  onFilter: PropTypes.func.isRequired,
};

NotificationsMenu.defaultProps = {
  filters: {},
};

export default NotificationsMenu;
