/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import l from '../../libs/localization';
import Form from '../Form';

const CreateVersionForm = props => {
  const { versionToBeModified, closingCB, onModify, visible } = props;
  const [checked, setChecked] = useState(
    versionToBeModified.data.shouldForceUpgrade
  );
  const [tempVersion, setTempNotification] = useState({});

  const handleOnChange = () => {
    setChecked(!checked);
  };
  useEffect(() => {
    setChecked(versionToBeModified.data.shouldForceUpgrade);
    setTempNotification({
      ...versionToBeModified.data,
    });
  }, [versionToBeModified]);
  const formComponent = (
    <div className="box box-primary">
      <div className="box-header with-border">
        <h3 className="box-title">{l('VERSION.CREATE')}</h3>
      </div>
      {/* <!-- /.box-header --> */}
      {/* <!-- form start --> */}
      <Form
        initialValue={versionToBeModified.data}
        onSubmit={e => {
          setTempNotification(oldTempNotification => {
            const mergedNotification = { ...oldTempNotification, ...e };

            mergedNotification['shouldForceUpgrade'] = checked;
            onModify({
              data: mergedNotification,
            });
            return mergedNotification;
          });
        }}
        className="w-100"
        role="form"
      >
        {({ controlInput, values }) => (
          <div className="box-body">
            <div className="form-group">
              <label>
                Should Force Upgrade &nbsp;
                <input
                  type="checkbox"
                  id="checked"
                  name="checked"
                  value="Paneer"
                  defaultChecked={checked}
                  // checked={checked}
                  onChange={handleOnChange}
                  // {...controlInput('shouldForceUpgrade')}
                />
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="VERSIONCode">{l('VERSION.IOS_VERSION')}</label>
              <input
                id="VERSIONCode"
                type="text"
                className="form-control notification-text"
                placeholder={l('VERSION.IOS_VERSION')}
                {...controlInput('currentIOSAppVersion')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="VERSIONUrl">{l('VERSION.ANDROID_VERSION')}</label>
              <input
                id="VERSIONUrl"
                type="text"
                className="form-control notification-text"
                placeholder={l('VERSION.ANDROID_VERSION')}
                {...controlInput('currentAndroidAppVersion')}
              />
            </div>

            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger m-0"
                onClick={closingCB}
              >
                <span className="glyphicon glyphicon-eject" />
                <b className="margin-left-10">{l('COMMON.CLOSE')}</b>
              </button>
              <button
                type="submit"
                disabled={
                  !values.currentIOSAppVersion ||
                  !values.currentAndroidAppVersion
                }
                className={
                  values.currentIOSAppVersion && values.currentAndroidAppVersion
                    ? 'btn btn-success m-0'
                    : 'btn btn-default m-0'
                }
              >
                <span className="glyphicon glyphicon-floppy-disk" />
                <b className="margin-left-10">{l('VERSION.SAVE')}</b>
              </button>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
  return (
    <ReactCSSTransitionGroup transitionName="VERSION-form-transition">
      {visible && formComponent}
    </ReactCSSTransitionGroup>
  );
};

CreateVersionForm.propTypes = {
  onModify: PropTypes.func,
  closingCB: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  formType: PropTypes.oneOf(['create', 'modify']).isRequired,
};

CreateVersionForm.defaultProps = {
  onModify: () => {},
};

export default CreateVersionForm;
