import { http } from './commons.api';
import { getConfig } from '../config';

// import log from '../libs/logger';

export const listImportsApi = async params => {
  const response = await http.post('/admin/graphql', {
    query: `
      query q ($token: String!) {
          root(token: $token ) {
            listFileImport {
              id
              type
              path
              status
              statusMessage
              createdAt
              processedAt
              processDuration
              downloadUrl
            }
          }
        }      
    `,
    variables: JSON.stringify({ token: getConfig('token') }),
  });
  return response.data.root.listFileImport;
};

export const uploadMasterdataApi = async params => {
  const response = await http.post('/api/v1/import/masterdata', params);
  return response;
};
export const uploadEanApi = async params => {
  const response = await http.post('/api/v1/import/ean', params);
  return response;
};
/*
export const loadDataLayoutApi = async id => {
  const response = await http.get(`/api/v1/structure`);
  return response;
};

export const loadCompressorApi = async ({ id }) => {
  const response = await http.get(`/api/v1/compressors/${id}`);
  return response;
};
*/
