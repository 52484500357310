import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  dealerListAction,
  dealerSetFilterAction,
  navigateTo,
  dealerSetFilterLocationAction,
} from '../actions';

import { formatDateTime } from '../libs/helpers';
import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import Button from '../components/Button';
import DealerListFilter from '../components/dealers/DealerListFilter';
import ButtonFilter from '../components/ButtonFilter';

const DealerListPage = ({
  data,
  search,
  geo,
  loading,
  message,
  filters,
  filterData,
  dealerList,
  dealerSetFilter,
  dealerSetFilterLocation,
  navigateTo,
}) => {
  useEffect(() => {
    dealerList(search, geo);
  }, [search, geo]);
  let rows;
  let count;
  try {
    count = data.count ? data.count : 0;
    data = data.list ? data.list : null;
  } catch (e) {
    count = 0;
    data = null;
  }

  console.log('Data: ', data);
  console.log(dealerSetFilterAction);

  if (data && data.length) {
    rows = data.map(r => (
      <tr key={r._id}>
        <td>
          <Link to={`/dealers/${r._id}`}>{r.dealername}</Link>
        </td>
        {/* <td>{r.dealerName}</td> */}
        <td>{r.brand1 ? r.brand1 : '-'}</td>
        <td>{r.addressline1 ? r.addressline1 : '-'}</td>
        <td>{r.addressline2 ? r.addressline2 : '-'}</td>
        <td>{r.city ? r.city : '-'}</td>
        <td>{r.postalCode ? r.postalCode : '-'}</td>
        <td>{r.country ? r.country : '-'}</td>
        <td>{r.countryid ? r.countryid : '-'}</td>
        <td>
          {r.location.coordinates[0] > 0 && r.location.coordinates[1] > 0 ? (
            <span className="glyphicon glyphicon-ok geotrue" />
          ) : (
            <span className="glyphicon glyphicon-remove geofalse" />
          )}
        </td>
      </tr>
    ));
    count = data.length;
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <Layout title={l('DEALER.DEALER_LIST_TITLE')}>
      <TableCard
        header={
          <DealerListFilter
            filters={filters}
            onFilter={dealerSetFilter}
            filterData={filterData}
          />
        }
      >
        <tbody>
          <tr className="table-header">
            <th>
              <Button text={l('DEALER.DEALER_NAME')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_BRAND')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_FIRST_ADDRESS')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_SECOND_ADDRESS')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_CITY')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_POSTAL_CODE')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_COUNTRY')} />
            </th>
            <th>
              <Button text={l('DEALER.DEALER_COUNTRY_ID')} />
            </th>
            <th>
              <Button
                text="GeoPosition"
                onClick={() => dealerSetFilterLocation('NoLocation')}
              />
            </th>
            {/* <th>{l('DEALER.DEALER_CONTACT_NUMBER')}</th> */}
          </tr>

          {rows}
          {/* <tr>
            <th>
              <button className="btn btn-secondary">
                <span className="glyphicon glyphicon-menu-left" />
                <b className="margin-left-10">{l('prev')}</b>
              </button></th>
            <th>
              <button className="btn btn-secondary">
                <b className="margin-left-10">{l('next')}</b>
                <span className="glyphicon glyphicon-menu-right" />
              </button></th>
          </tr> */}
        </tbody>
      </TableCard>
      <Loader visible={loading} />
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.dealers,
});

const mapDispatchToProps = {
  dealerList: dealerListAction,
  dealerSetFilter: dealerSetFilterAction,
  dealerSetFilterLocation: dealerSetFilterLocationAction,
  navigateTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerListPage);
