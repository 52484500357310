import React from 'react';
import PropTypes from 'prop-types';
import log from '../libs/logger';

const FormContext = React.createContext({
  values: {},
  validations: {},
  onChangeValue: () => {},
  controlInput: () => ({}),
  filterGeo: () => ({}),
});

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.initialValue || {},
      validations: {},
    };

    this.changeValue = this.changeValue.bind(this);
    this.controlInput = this.controlInput.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.filterGeo = this.filterGeo.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  parseValue(val, options = {}) {
    let returnValue = val;
    if (val.target && val.target.value !== undefined) {
      returnValue = val.target.value;
    }

    if (
      options &&
      options.nullValue !== undefined &&
      options.nullValue === returnValue
    ) {
      returnValue = null;
    }

    return returnValue;
  }

  controlInput(name, options = {}) {
    const { values } = this.state;
    return {
      value: values[name] || '',
      onChange: elem =>
        this.changeValue(name, this.parseValue(elem, options), options),
    };
  }

  filterGeo(name, options = {}) {
    const { values } = this.state;
    return {
      value: values[name] || '',
      onChange: elem =>
        this.changeValue(name, this.parseValue(elem, options), options),
    };
  }

  changeValue(key, value) {
    // log.debug(`${key}: ${value}`);
    this.setState(oldState => {
      const values = {
        ...oldState.values,
        [key]: value,
      };
      return { values };
    });
  }

  requestSubmit(event) {
    if (event) event.preventDefault();
    log.debug('submit');
    const { onSubmit } = this.props;
    if (onSubmit) {
      const { values } = this.state;
      onSubmit(values);
    }
  }

  render() {
    // console.log('form', this.state);
    const { children, className } = this.props;
    const { values, validations } = this.state;
    const formParams = {
      values,
      validations,
      onChangeValue: this.changeValue,
      controlInput: this.controlInput,
      filterGeo: this.filterGeo,
    };

    return (
      <form onSubmit={this.requestSubmit} className={className}>
        <FormContext.Provider value={formParams}>
          {typeof children === 'function' ? children(formParams) : children}
        </FormContext.Provider>
      </form>
    );
  }
}

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  className: '',
  initialValue: {},
  onSubmit: null,
};

export default Form;
