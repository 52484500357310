import { reducerFromMap } from './common.reducers';

import {
  USER_LOGIN,
  USER_LOGIN_COMPLETE,
  USER_LOGIN_ERROR,
  USER_LOGIN_HIDE_MESSAGE,
  USER_LOGOUT,
  USER_OAUTH_LOGIN_ERROR,
  USER_UPDATE_USER_INFO,
} from '../actions';

const initialState = {
  loading: false,
  name: null,
  email: null,
  token: null,
  message: null,
  roles: null,
};

const reducerMap = {};

reducerMap[USER_LOGIN] = (state, action) => ({
  ...state,
  loading: true,
  message: null,
});

reducerMap[USER_LOGIN_COMPLETE] = (state, action) => ({
  ...state,
  loading: false,
  name: action.data.name,
  token: action.data.token,
  email: action.data.email,
  id: action.data.id,
  roles: action.data.roles,
});

reducerMap[USER_UPDATE_USER_INFO] = (state, action) => ({
  ...state,
  ...action.data,
  loading: false,
  message: null,
});

reducerMap[USER_LOGIN_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message:
    action.error.message === 'notAuthorized'
      ? 'Invalid credentials'
      : 'Error during login',
});

reducerMap[USER_OAUTH_LOGIN_ERROR] = (state, action) => ({
  ...state,
  loading: false,
  message:
    action.error.message === 'notAuthorized'
      ? 'User not authorized'
      : 'Error during login',
});

reducerMap[USER_LOGIN_HIDE_MESSAGE] = state => ({
  ...state,
  message: null,
});

reducerMap[USER_LOGOUT] = () => initialState;

export default reducerFromMap(reducerMap, initialState);
