export default {
  AIRTUBE: 'AirTube',
  COMMON: {
    SEARCH: 'Search',
    NO_DATA_FOUND: 'No data found',
    LAST_UPDATE: 'Last update',
    DESCRIPTION: 'Description',
    BRAND: 'Brand',
    COMPRESSORS: 'compressors',
    YES: 'Yes',
    NO: 'No',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    EDIT: 'Edit',
    DELETE: 'Delete',
    CHOOSE: 'Please choose an option',
    BACK_TO_LIST: 'Back to list',
    SUMMARY: 'Summary',
    SECTION: 'Section',
    SHOW_FIELDS: 'Show fields',
    DATA_ALL: 'All data',
    DATA_ONLY_FILLED: 'Only with data',
    NEXT: 'Next',
    PREVIOUS: 'Previous',
    ERROR: 'Error:',
    DISMISS: 'Dismiss',
  },
  MENU: {
    COMPRESSOR: 'COMPRESSOR MODELS',
    IMPORT: 'IMPORT EXCEL',
    NOTIFICATIONS: 'NOTIFICATIONS',
    PRIVACY: 'PRIVACY POLICY',
    USER: 'USER MANAGEMENT',
    DEALER: 'DEALER LIST',
    SHOP: 'SHOP LIST',
  },
  LANGS: {
    null: '-',
    en: 'English',
    it: 'Italian',
    de: 'German',
    fr: 'French',
    es: 'Espanol',
  },
  COMPRESSOR: {
    MODEL_LIST_TITLE: 'Compressor models',
    SEARCH_PLACEHOLDER: 'text to search',
    PARTNUMBER: 'Part number',
  },
  DEALER: {
    DEALER_LIST_TITLE: 'Dealer list',
    SEARCH_PLACEHOLDER: 'text to search',
    DEALER_NAME: 'Dealer name',
    DEALER_EMAIL: 'Email',
    DEALER_BRAND: 'Brand',
    DEALER_FIRST_ADDRESS: 'Address line 1',
    DEALER_SECOND_ADDRESS: 'Address line 2',
    DEALER_CITY: 'City',
    DEALER_COUNTRY: 'Country',
    DEALER_COUNTRY_ID: 'CountryID',
    DEALER_POSTAL_CODE: 'Postal Code',
    DEALER_CONTACT_NUMBER: 'Contact number',
    DEALER_POSITION: 'Geo Position',
  },
  SHOP: {
    SHOP_LIST_TITLE: 'Shop list',
    SHOP_TITLE_DETAIL: 'Shop details',
    SHOP_BRAND: 'Brand',
    SHOP_COUNTRY_NAME: 'Country name',
    SHOP_COUNTRY_CODE: 'Country code',
    SHOP_NAME: 'Name',
    SHOP_ICON: 'Icon',
    SHOP_URL_PARTS: 'Url parts',
    SHOP_URL_HOME: 'Url home',
    SHOP_URL_LUBRICANTS: 'Url lubricants',
    SHOP_URL_TOOLS: 'Url tools',
    SHOP_URL_COMPRESSORS: 'Url compressors',
    SHOP_URL_AIR_INSTALLATIONS: 'Url air installations',
  },
  FILES_IMPORT: {
    PAGE_TITLE: 'Import Files',
    MASTERDATA: 'Import masterdata',
    ASSOCIATIONS: 'Import ean/serial/pn association',
    DEALER_SHOP: 'Import Dealers/shops',
    TYPE: 'Type',
    NAME: 'File name',
    STATUS: 'Status',
    CREATED_AT: 'Creation Date',
    PROCESSED_AT: 'Processing Date',
    DOWNLOAD: 'Download',
    UPLOAD_BUTTON: 'Upload Sheet',
    UPLOAD_COMPLETE: 'Upload Complete',
    UPLOAD_CLEAR: 'Clear Files',
    UPLOAD_PLACEHOLDER: 'Click or drop files to upload',
    PROCESSING: 'Processing files, please wait...',
    PROCESSING_COMPLETE: 'Processing complete',
  },
  NOTIFICATION: {
    LIST_TITLE: 'Notifications list',
    CREATE: 'Create New',
    MODIFY: 'Modify notification',
    SEARCH_FROM: 'From:',
    SEARCH_TO: 'To:',
    TEXT: 'Notification Text',
    CREATED_AT: 'Creation Date (GMT)',
    PLANNED_AT: 'Planned Sending Date (GMT)',
    SAVE: 'Save',
    SAVE_DRAFT: 'Save as Draft',
    SENT: 'Sent',
    STATUS: 'Status',
    LANGUAGE: 'Language',
    FILTER: 'Apply Filters',
    'EDIT-TOOLTIP': 'Edit notification',
    'DELETE-TOOLTIP': 'Delete notification',
  },
  USERS: {
    PAGE_TITLE: 'User management',
    FILTER: 'FILTER',
    NAME: 'Name',
    EMAIL: 'Email',
    LIST: 'USER LIST',
    ADD: 'ADD ADMIN',
    TYPE: 'Type',
    LAST_ACCESS: 'Last Access',
    active: 'Active',
    disabled: 'Disabled',
    SAVE: 'Create',
    ROLES: {
      ADMIN: 'Admin',
      USER: 'Piston Club',
      APP: 'App',
    },
    CONFIRM: {
      ACTIVATION: 'Are you sure you wish to change the activation status?',
      DELETE: 'Are you sure you wish to delete the user?',
      RECOVER:
        'Are you sure you wish to send and email to the user to recover his password?',
    },
    SUCCESS: {
      RECOVER: 'Recovery email sent',
    },
    COUNTRY: 'Country',
    FIRSTNAME: 'Firstname',
    LASTNAME: 'Lastname',
  },
  PRIVACY: {
    CREATE: 'Add Privacy Policy',
    PAGE_TITLE: 'Privacy Policies',
    CODE: 'Version',
    URL: 'URL',
    CREATED_AT: 'Creation Date',
    VALID_FROM: 'Valid from',
    COUNT: 'User Count',
    SAVE: 'Add Privacy Policy',
    MAKE_PRIMARY: 'Activate policy',
    INTRO:
      'The current active privacy policy is the one highlighted in blue. To activate a new policy, simply click on the edit button. Only inactive policies and policies with no users associated may be safely deleted.',
  },
  VERSION: {
    PAGE_TITLE: 'Version',
    CREATE: 'Add Version',
    IOS_VERSION: 'CurrentIOSAppVersion',
    ANDROID_VERSION: 'CurrentAndroidAppVersion',
    APP_STORE_URL: 'IOSAppStoreURL',
    PLAYSTORE_URL: 'AndroidPlayStoreURL',
    EDIT: 'Edit',
    SAVE: 'Save',
  },
};
