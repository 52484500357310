import { push } from 'connected-react-router';

import { refreshPage } from '../libs/helpers';
import {
  APP_START,
  USER_LOGIN_COMPLETE,
  USER_LOGOUT,
  ROUTER_LOCATION_CHANGE,
  userUpdateUserInfo,
  userOAuthLogin,
  checkUserAction,
} from '../actions';
import { setConfig } from '../config';

const localStorageKey = 'atUserInfo';

const getLocalStorageUserInfo = () => {
  const storageUserInfo = localStorage.getItem(localStorageKey);
  if (!storageUserInfo) return null;
  return JSON.parse(storageUserInfo);
};

const setLocalStorageUserInfo = userInfo =>
  localStorage.setItem(localStorageKey, JSON.stringify(userInfo));

const removeLocalStorageUserInfo = () =>
  localStorage.removeItem(localStorageKey);

const getStateUserInfo = store => store.getState().user;

const checkUser = function checkUser(store) {
  const storageUserInfo = getLocalStorageUserInfo();

  const storageInfoId = storageUserInfo && storageUserInfo.id;
  const storageInfoToken = storageUserInfo && storageUserInfo.token;

  const { id, token } = getStateUserInfo(store);

  if (id && id !== storageInfoId) {
    refreshPage();
  } else if (!token || token !== storageInfoToken) {
    setConfig('token', storageInfoToken);
    store.dispatch(userUpdateUserInfo(storageUserInfo));
    if (storageInfoToken) store.dispatch(checkUserAction());
  }
};

const checkOAuthResponse = store => {
  let { hash } = store.getState().router.location;

  if (hash) {
    [, hash] = hash.split('#');
    const params = {};
    hash.split('&').forEach(t => {
      const [k, v] = t.split('=');
      params[k] = v;
    });
    if (params.id_token) {
      store.dispatch(userOAuthLogin(params.id_token, params.access_token));
    }
  }

  return false;
};

const authMiddleware = store => next => action => {
  if (action.type === APP_START) {
    if (!checkOAuthResponse(store)) {
      checkUser(store);
    }
  } else if (action.type === ROUTER_LOCATION_CHANGE) {
    checkUser(store);
  } else if (action.type === USER_LOGOUT) {
    removeLocalStorageUserInfo();
    setConfig('token', null);
  }

  console.log({ auth: 1, action });

  next(action);

  if (action.type === USER_LOGIN_COMPLETE) {
    setLocalStorageUserInfo(action.data);
    setConfig('token', action.data.token);
    store.dispatch(push('/'));
  }
};

export default authMiddleware;
