import React from 'react';
import PropTypes from 'prop-types';
import l from '../../libs/localization';
import Form from '../Form';
import { usersSearchAction } from '../../actions';
import { listUsersApi } from '../../api/users.api';
import User from './User';
import UserMgmtPage from '../../containers/UserMgmtPage';
const UsersMenu = ({ filters, onFilter, dataM, data, filtable }) => {
  var UserCompressorInfo = [];
  var CompressorDATA = [];
  var defaultPartnumber = '';
  var brand = [];

  UserCompressorInfo = JSON.parse(localStorage.getItem('UserCompressorInfo'));
  CompressorDATA = JSON.parse(localStorage.getItem('CompressorDATA'));

  const saveExcel = (fileName = 'UsersListExport.csv', data) => {
    if (filters.type === 'user') {
      dataM = data.map(d => {
        defaultPartnumber = '';
        brand = '';
        UserCompressorInfo.map(r => {
          if (filters.type === 'user') {
            if (d.email == r.userId) {
              defaultPartnumber = r.defaultCompressor;
            }
          }
        });
        CompressorDATA.map(c => {
          if (defaultPartnumber === c._id) {
            brand = c.data.brand;
          }
        });
        if (d.language == null) {
          d.language = 'en';
        }
        if (d.firstName !== null) {
          d.name = d.firstName;
        }
        return [
          d.email,
          d.name,
          d.lastName,
          d.type,
          d.language,
          d.country,
          brand,
          defaultPartnumber,
          d.lastLogin,
        ];
      });

      const header = [
        'Email',
        'Firstname',
        'Lastname',
        'Type',
        'Language',
        'Country',
        'Brand',
        'Part Number',
        'Last Access',
        '\n',
      ];
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display:block';
      const blob = new Blob([header, dataM.join('\n')], {
        type: 'application/octet-stream',
        // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'UsersListExport.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Form
      className="d-flex noti-menu"
      onSubmit={onFilter}
      initialValue={filters}
    >
      {({ controlInput }) => (
        <>
          <div className="filters users-search">
            <div className="input-group ">
              <span className="input-group-addon c-black">
                <span className="glyphicon glyphicon-search" />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={l('COMMON.SEARCH')}
                {...controlInput('search')}
              />
            </div>
          </div>
          <div className="input-group">
            <span className="c-black margin-left-10 margin-r-5">
              {`${l('USERS.TYPE')}:`}
            </span>
            <select
              type="select"
              className="form-control noti-select"
              placeholder={l('USERS.TYPE')}
              {...controlInput('type')}
            >
              <option value="admin">{l(`USERS.ROLES.ADMIN`)}</option>
              <option value="user">{l(`USERS.ROLES.USER`)}</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary margin-left-10">
            <span className="glyphicon glyphicon-filter" />
            <b>{l('USERS.FILTER')}</b>
          </button>
          <button
            id="export"
            className="btn btn-primary margin-left-10"
            onClick={e => saveExcel(dataM, data)}
          >
            <span className="glyphicon glyphicon-plus" />
            <b>EXPORT USER IN EXCEL</b>
          </button>
        </>
      )}
    </Form>
  );
};

UsersMenu.propTypes = {
  filters: PropTypes.shape({
    type: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

UsersMenu.defaultProps = {};

export default UsersMenu;
