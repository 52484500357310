import { push } from 'connected-react-router';
import { callApi } from './commons.actions';
import { listShopApi, oneShopApi, loadDataLayoutApi } from '../api/shops.api';
import { encodeParameters } from '../api/commons.api';

// actions
// export const SHOPS_SET_FILTER = 'SHOPS_SET_FILTER';
export const SHOPS_LIST = 'SHOPS_LIST';
export const SHOPS_LIST_COMPLETE = 'SHOPS_LIST_COMPLETE';
export const SHOPS_LIST_ERROR = 'SHOPS_LIST_ERROR';

export const SHOPS_LIST_NEXT = 'SHOPS_LIST_NEXT';
export const SHOPS_LIST_NEXT_COMPLETE = 'SHOPS_LIST_COMPLETE_NEXT';
export const SHOPS_LIST_NEXT_ERROR = 'SHOPS_LIST_ERROR_NEXT';

export const SHOPS_LIST_PREV = 'SHOPS_LIST_PREV';
export const SHOPS_LIST_PREV_COMPLETE = 'SHOPS_LIST_PREV_COMPLETE';
export const SHOPS_LIST_PREV_ERROR = 'SHOPS_LIST_PREV_ERROR';

export const SHOPS_LOAD_FILTERS = 'SHOPS_LOAD_FILTERS';
export const SHOPS_LOAD_FILTERS_COMPLETE = 'SHOPS_LOAD_FILTERS_COMPLETE';
export const SHOPS_LOAD_FILTERS_ERROR = 'SHOPS_LOAD_FILTERS_ERROR';

export const SHOP_LOAD = 'SHOP_LOAD';
export const SHOP_LOAD_COMPLETE = 'SHOP_LOAD_COMPLETE';
export const SHOP_LOAD_ERROR = 'SHOP_LOAD_ERROR';

export const DATALAYOUT_SHOP_LOAD = 'DATALAYOUT_SHOP_LOAD ';
export const DATALAYOUT_SHOP_LOAD_COMPLETE = 'DATALAYOUT_SHOP_LOAD_COMPLETE';
export const DATALAYOUT_SHOP_LOAD_ERROR = 'DATALAYOUT_SHOP_LOAD_ERROR';

export const shopListAction = callApi({
  apiCall: (p = {}) => listShopApi(p),
  startAction: SHOPS_LIST,
  successAction: SHOPS_LIST_COMPLETE,
  errorAction: SHOPS_LIST_ERROR,
});

export const shopNextListAction = callApi({
  apiCall: p => listShopApi(p),
  startAction: SHOPS_LIST_NEXT,
  successAction: SHOPS_LIST_NEXT_COMPLETE,
  errorAction: SHOPS_LIST_NEXT_ERROR,
});

export const shopSetFilterAction = (p = {}) => dispatch => {
  dispatch(push(encodeParameters(p, '/shops')));
  dispatch(shopListAction(p));
};

export const dataLayoutLoad = callApi({
  apiCall: loadDataLayoutApi,
  startAction: DATALAYOUT_SHOP_LOAD,
  successAction: DATALAYOUT_SHOP_LOAD_COMPLETE,
  errorAction: DATALAYOUT_SHOP_LOAD_ERROR,
});

export const shopLoadAction = callApi({
  apiCall: oneShopApi,
  startAction: SHOP_LOAD,
  successAction: SHOP_LOAD_COMPLETE,
  errorAction: SHOP_LOAD_ERROR,
  onStart: (params, dispatch, getState) => {
    const shopState = getState().shop;
    // if (!shopState.dataLayout && !shopState.dataLayoutLoading) {
    //   dispatch(dataLayoutLoad());
    // }
  },
  processResult: (result, params, dispatch, getState) => {
    console.log({ result });
  },
});
