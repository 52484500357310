/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  notificationListAction,
  notificationSearchAction,
  notificationCreateAction,
  notificationDeleteAction,
  notificationStatusChangeAction,
  notificationEditAction,
} from '../actions';

import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import ErrorToast from '../components/notifications/ErrorToast';
import NotificationsMenu from '../components/notifications/NotificationsMenu';
import CreateNotificationForm from '../components/notifications/CreateNotificationForm';
import NotificationsTable from '../components/notifications/NotificationsTable';

const NotificationListPage = ({
  data,
  loading,
  message,
  filters,
  notificationList,
  notificationSetFilter,
  notificationCreate,
  notificationDelete,
  notificationChangeStatus,
  notificationEdit,
}) => {
  const [formMode, setFormMode] = useState('create');
  const [formVisibility, setFormVisibility] = useState(false);
  const [tempNotification, setTempNotification] = useState({
    id: null,
    statusMessage: null,
    text: null,
    plannedSendAt: null,
    createdAt: null,
    status: null,
    language: null,
  });
  const toggleFormVisibility = override => {
    // override area
    if (override === true || override === false) {
      setFormVisibility(override);
      return;
    }
    // actual toggle
    setFormVisibility(!formVisibility);
  };
  const toggleFormMode = override => {
    // override area
    if (override) {
      setFormMode(override);
      return;
    }
    // actual toggle
    if (formMode === 'create') setFormMode('modify');
    else setFormMode('create');
  };
  const toggleCreateButton = () => {
    toggleFormMode('create');
    toggleFormVisibility(true);
  };
  const toggleEditButton = notification => {
    toggleFormMode('modify');
    setTempNotification(notification);
    toggleFormVisibility(true);
  };

  useEffect(() => {
    notificationList(filters);
  }, [filters, notificationList]);
  useEffect(() => {
    toggleFormVisibility(false);
  }, [data]);

  const [erasableMessage, setErasableMessage] = useState(message);
  useEffect(() => {
    setErasableMessage(message);
  }, [message]);

  return (
    <Layout title={l('NOTIFICATION.LIST_TITLE')}>
      <div className="notifications-container">
        <TableCard
          header={
            <div className="notification-header">
              <NotificationsMenu
                filters={filters}
                onFilter={notificationSetFilter}
              />
              <button
                type="button"
                className="btn btn-success noti-create-button noti-menu-button"
                onClick={toggleCreateButton}
              >
                <span className="glyphicon glyphicon-plus" />
                <b className="margin-left-10">{l('NOTIFICATION.CREATE')}</b>
              </button>
            </div>
          }
        />
        <div className="absolute-table&form-container">
          <ErrorToast
            message={erasableMessage}
            eraseMessage={setErasableMessage}
          />
          <CreateNotificationForm
            visible={formVisibility}
            formType={formMode}
            onCreate={notificationCreate}
            onModify={notificationEdit}
            notificationToBeModified={tempNotification}
            closingCB={() => {
              toggleFormVisibility(false);
              notificationList();
            }}
          />
          <NotificationsTable
            data={data}
            filters={filters}
            notificationDelete={notificationDelete}
            notificationChangeStatus={notificationChangeStatus}
            toggleEditButton={toggleEditButton}
            visible={!formVisibility}
          />
        </div>
      </div>
      <Loader visible={loading} />
    </Layout>
  );
};

NotificationListPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  message: PropTypes.string,
  filters: PropTypes.shape({
    dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string,
    language: PropTypes.string,
  }),
  notificationList: PropTypes.func.isRequired,
  notificationSetFilter: PropTypes.func.isRequired,
  notificationCreate: PropTypes.func.isRequired,
  notificationDelete: PropTypes.func.isRequired,
  notificationChangeStatus: PropTypes.func.isRequired,
  notificationEdit: PropTypes.func.isRequired,
};

NotificationListPage.defaultProps = {
  data: [],
  loading: true,
  message: null,
  filters: {},
};

const mapStateToProps = state => ({
  ...state.notifications,
});

const mapDispatchToProps = {
  notificationList: notificationListAction,
  notificationSetFilter: notificationSearchAction,
  notificationCreate: notificationCreateAction,
  notificationDelete: notificationDeleteAction,
  notificationChangeStatus: notificationStatusChangeAction,
  notificationEdit: notificationEditAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationListPage);
