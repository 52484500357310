import { http } from './commons.api';

// import log from '../libs/logger';

export const listDealerApi = async params => {
  const response = await http.get('/api/v1/dealers', params);
  return response;
};

export const listDealerNoLocationApi = async params => {
  const response = await http.get(`/api/v1/dealers?&search=${params}`);
  console.log(response);
  return response;
};

export const loadDataLayoutApi = async params => {
  const response = await http.get('/api/v1/structureDealer', params);
  return response;
};

export const oneDealerApi = async ({ id }) => {
  const response = await http.get(`/api/v1/dealers/${id}`);
  return response;
};
