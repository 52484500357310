import React, { /* useState, useCallback, */ useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';

import { dealerLoadAction } from '../actions';
import Card from '../components/Card';
import Layout from '../layouts/Layout';
import Loader from '../components/Loader';
import ModelDetail from '../components/modelDetail/ModelDetail';
import l from '../libs/localization';

const DealerDetailPage = ({
  match,
  location,
  loading,
  dataLayoutLoading,
  data,
  dataLayout,
  message,
  mode,
  dealerLoad,
}) => {
  const dealer = data ? data.data : {};
  console.log('Dealer: ', dealer);
  let title;
  try {
    title = `${dealer.dealername ? dealer.dealername : ''} - ${
      dealer.brand1 ? dealer.brand1 : ''
    }`;
  } catch (e) {
    title = 'Detail';
  }

  /*
  const [id, tag, tags, title] = [
    data.id,
    data.tag,
    data.tags,
    project ? `${project.db_id} - ${project.title}` : null,
  ];
*/
  //console.log('READY: ',  !dataLayoutLoading, !loading , data , dataLayout)
  const ready = !dataLayoutLoading && !loading && data; /* && dataLayout*/
  //console.log('READY: ' , ready)
  const { id: queryId } = match.params;
  useEffect(() => {
    dealerLoad({ id: queryId });
  }, [queryId, location.search, location.geo]);

  return (
    <Layout>
      {message ? (
        <div className="callout callout-danger">
          <p>{message}</p>
        </div>
      ) : (
        ''
      )}
      <div className="page-nav-links">
        <Link to="/dealers" className="btn btn-primary">
          {l('COMMON.BACK_TO_LIST')}
        </Link>
      </div>

      {ready ? (
        // <div className="project-detail">
        //   <h1>{dealer.partNumber ? dealer.commercialDescription : 'TEST'}</h1>
        //   <ModelDetail model={data} dataLayout={dataLayout.tree} showAll />
        // </div>
        <div className="project-detail">
          <h1>
            {data.dealername ? data.dealername : ''} -{' '}
            {data.brand1 ? data.brand1 : ''}
          </h1>
          <Card>
            {/* <h2 className="">{'Details'}</h2> */}
            <div className="col-xs-2 dealerDetails">
              <p>
                <b>{l('DEALER.DEALER_NAME')}</b>
              </p>
              <p>{l('DEALER.DEALER_EMAIL')}:</p>
              <p>{l('DEALER.DEALER_BRAND')}:</p>
              <p>{l('DEALER.DEALER_FIRST_ADDRESS')}:</p>
              <p>{l('DEALER.DEALER_SECOND_ADDRESS')}:</p>
              <p>{l('DEALER.DEALER_POSTAL_CODE')}:</p>
              <p>{l('DEALER.DEALER_CITY')}:</p>
              <p>{l('DEALER.DEALER_COUNTRY')}:</p>
              <p>{l('DEALER.DEALER_COUNTRY_ID')}: </p>
              <p>{l('DEALER.DEALER_CONTACT_NUMBER')}: </p>
              <p>{l('DEALER.DEALER_POSITION')}: </p>
            </div>
            <div className="col-xs-4">
              <p>{data.dealername ? data.dealername : '-'}</p>
              <p>{data.email ? data.email : '-'}</p>
              <p>{data.brand1 ? data.brand1 : '-'}</p>
              <p>{data.addressline1 ? data.addressline1 : '-'}</p>
              <p>{data.addressline2 ? data.addressline2 : '-'}</p>
              <p>{data.postalcode ? data.postalcode : '-'}</p>
              <p>{data.city ? data.city : '-'}</p>
              <p>{data.country ? data.country : '-'}</p>
              <p>{data.countryid ? data.countryid : '-'}</p>
              <p>{data.contactnumber ? data.contactnumber : '-'}</p>
              <p>
                {data.location.coordinates[0] > 0 &&
                data.location.coordinates[1] > 0
                  ? `lat[ ${Math.round(
                      (data.location.coordinates[0] + Number.EPSILON) * 100
                    ) / 100} ], long[ ${Math.round(
                      (data.location.coordinates[1] + Number.EPSILON) * 100
                    ) / 100} ]`
                  : '-'}
              </p>
            </div>
          </Card>

          {/* <ModelDetail model={data} dataLayout={dataLayout.tree} showAll /> */}
        </div>
      ) : (
        <Loader visible={loading || dataLayoutLoading} />
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  ...state.dealer,
});

const mapDispatchToProps = {
  dealerLoad: dealerLoadAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerDetailPage);
