import React from 'react';
import PropTypes from 'prop-types';

const Card = ({
  title,
  headerTools,
  header,
  children,
  colorModifier,
  footer,
  bodyClassName,
  className,
}) => {
  const bodyClass = `box-body ${bodyClassName}`;
  const boxClass = `box ${className}`;

  let headerContents = null;

  if (header) headerContents = header;
  else if (title || headerTools) {
    headerContents = [];
    if (title)
      headerContents.push(
        <h3 key="t" className="box-title">
          {title}
        </h3>
      );
    if (headerTools) {
      headerContents.push(
        <div key="tools" className="box-tools">
          {headerTools}
        </div>
      );
    }
  }

  return (
    <div className={boxClass}>
      {headerContents ? (
        <div className="box-header">{headerContents}</div>
      ) : null}
      {children ? <div className={bodyClass}>{children}</div> : null}
      {footer ? <div className="box-footer">{footer}</div> : null}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  headerTools: PropTypes.node,
  header: PropTypes.node,
  children: PropTypes.node,
  colorModifier: PropTypes.string,
  bodyClassName: PropTypes.string,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  headerTools: null,
  header: null,
  colorModifier: null,
  bodyClassName: null,
  className: null,
  children: null,
};

export default Card;
