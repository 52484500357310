/* eslint-disable jsx-a11y/label-has-for */
import React /* ,{ useState, useEffect } */ from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6
import PropTypes from 'prop-types';

import { formatDateTime } from '../../libs/helpers';
import l from '../../libs/localization';
import TableCard from '../TableCard';
import Version from './Version';

const VersionTable = props => {
  const { data, visible, toggleEditButton } = props;
  let rows;
  console.log('...........Version table.........:', data);

  if (data && data.length) {
    rows = data.map(r => (
      <Version key={r.id} r={r} modifyNotification={toggleEditButton} />
    ));
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <ReactCSSTransitionGroup transitionName="privacy-table-transition">
      {visible && (
        <TableCard>
          <tbody>
            <tr className="table-header">
              <th>{l('VERSION.IOS_VERSION')}</th>
              <th>{l('VERSION.ANDROID_VERSION')}</th>
              <th>{l('VERSION.APP_STORE_URL')}</th>
              <th>{l('VERSION.PLAYSTORE_URL')}</th>
              <th>{l('COMMON.EDIT')}</th>
            </tr>

            {rows}
          </tbody>
        </TableCard>
      )}
    </ReactCSSTransitionGroup>
  );
};

VersionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  visible: PropTypes.bool.isRequired,
  toggleEditButton: PropTypes.func.isRequired,
  //   policyDelete: PropTypes.func.isRequired,
  //   policyActivate: PropTypes.func.isRequired,
};

VersionTable.defaultProps = {
  data: [],
};

export default VersionTable;
