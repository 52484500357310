import { push } from 'connected-react-router';
// actions
export const APP_START = '@@APP_START';
export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const APP_INIT_COMPLETE = 'APP_INIT_COMPLETE';

export const appInitComplete = () => ({ type: APP_INIT_COMPLETE });

export const navigateTo = destPage => push(destPage);
