const defaultRootUri = process.env.REACT_APP_ROOT_URL || window.baseUrl;

const defaultApiUrl = process.env.REACT_APP_API_URL || rootUri;

const baseConfig = window.atConfig || {};

const config = {
  apiUrl: defaultApiUrl,
  rootUri: defaultRootUri,
  forceLocale: 'en',
  logger: true,
  ...baseConfig,
};

if (!config.apiUrl.startsWith('http')) {
  config.apiUrl = `${window.location.origin}${config.apiUrl}`;
}

config.setConfig = (key, value) => (config[key] = value);
config.getConfig = key => config[key];

export default config;

export const {
  apiUrl,
  rootUri,
  forceLocale,
  logger,
  setConfig,
  getConfig,
} = config;
