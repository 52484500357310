/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Dropzone.css';

import l from '../../../libs/localization';

function fileListToArray(list) {
  const array = [];
  for (let i = 0; i < list.length; i++) {
    array.push(list.item(i));
    console.log('list item', list.item(i));
  }
  return array;
}
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { highlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onFilesAdded(evt) {
    const { disabled, onFilesAdded } = this.props;
    if (disabled) return;
    const { files } = evt.target;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
    }
  }

  onDragOver(event) {
    const { disabled } = this.props;
    event.preventDefault();
    if (disabled) return;
    this.setState({ highlight: true });
  }

  onDragLeave() {
    this.setState({ highlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const { onFilesAdded, disabled } = this.props;
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (onFilesAdded) {
      const array = fileListToArray(files);
      onFilesAdded(array);
    }
    this.setState({ highlight: false });
  }

  openFileDialog() {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  render() {
    const { disabled, title } = this.props;
    const { highlight } = this.state;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={`Dropzone ${highlight ? 'Highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <h4>{title}</h4>
        <span className="c-black glyphicon glyphicon-import fileimport-icon" />
        <span className="c-black">{l('FILES_IMPORT.UPLOAD_PLACEHOLDER')}</span>
      </div>
    );
  }
}
Dropzone.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
};

export default Dropzone;
