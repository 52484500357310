import { parseJwt } from '../libs/helpers';
import { http } from './commons.api';
import { getConfig } from '../config';
// import log from '../libs/logger';

export const loginApi = async (email, password) => {
  const response = await http.post('/api/v1/login', {
    email,
    password,
    role: 'admin',
  });

  if (response.status === 'ok') {
    const userInfo = parseJwt(response.token);

    return {
      ...response,
      ...userInfo,
      email: userInfo.id,
    };
  }

  throw new Error(response.status);
};

export const checkAuthApi = async (email, password) => {
  const response = await http.get('/api/v1/me');

  if (response.status === 'ok') {
    return response;
  }

  throw new Error(response.status);
};

export const oauthLoginApi = async (id_token, access_token) => {
  const response = await http.post('/api/v1/login/google', {
    id_token,
    access_token,
  });

  if (response.status === 'ok') {
    const userInfo = parseJwt(response.token);

    return {
      ...response,
      ...userInfo,
    };
  }

  throw new Error(response.status);
};
export const listUsersApi = async params => {
  let { type, search } = params;
  if (type === '') {
    type = null;
  }
  if (search === '') {
    search = null;
  }
  const response = await http.post('/admin/graphql', {
    query: `
    query q ($token: String! $search: String $type: UserRole ) {
    root(token: $token ) {
      users (search: $search type: $type)  {
        email
        name
        type
        active
        lastLogin
        language
        country
        firstName
        lastName
        }
      }
    }
  `,
    variables: JSON.stringify({ token: getConfig('token'), type, search }),
  });
  return { data: response.data.root.users, filters: params };
};
export const createUserApi = async params => {
  const response = await http.post('/admin/graphql', {
    query: `
    mutation addUser ($token: String! $plannedSendAt: Timestamp! $text: String! $language: Language! ) {
        prepareUser(
          token:$token
          text: $text
          plannedSendAt: $plannedSendAt
          language: $language
        ) {         
          email
          name
          type
          active
          lastLogin
         
        }
     }
    `,
    variables: JSON.stringify({
      token: getConfig('token'),
      ...params,
    }),
  });
  return response.data.prepareUser;
};

export const deleteUserApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
      mutation deleteUser($token: String! $email: String!) {
        deleteUser(
            token:$token
            email: $email
          )
       }
      `,
      variables: JSON.stringify({
        token: getConfig('token'),
        email: params.email,
      }),
    })
    .then(() => listUsersApi(params.filters));
  return response;
};

export const editUserApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation editUser ($token: String! $name: String! $active: Boolean $email: String! $type: UserRole) {
        changeUser(
          email: $email
          token:$token
          name: $name
          active: $active
          type: $type
        ) {         
          email
          name
          type
          active
}
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params.data,
      }),
    })
    .then(() => {
      return listUsersApi(params.filters);
    });
  return response;
};
export const recoverUserPasswordApi = async params => {
  const response = await http.post('/admin/graphql', {
    query: `
      mutation deleteUser($token: String! $email: String!) {
        recoverPassword(
            token:$token
            email: $email
          )
       }
      `,
    variables: JSON.stringify({
      token: getConfig('token'),
      email: params.email,
    }),
  });
  return response;
};
export const userListExportApi = () => {
  const response = http.getRaw(
    'https://airtube-dev.herokuapp.com/api/v1/export-user-emails'
  );
  return response;
};
