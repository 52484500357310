import React from 'react';
import PropTypes from 'prop-types';

import l from '../../libs/localization';

import Section from './Section';
import Card from '../Card';

const Page = ({ mode, dataLayout, model, showAll }) => {
  const effectiveShowAll = showAll || mode === 'edit';

  const className = dataLayout.answers ? 'box-success' : '';

  return (
    <Card className={className} header={<h3>{dataLayout.title}</h3>}>
      {dataLayout.answers || effectiveShowAll ? (
        dataLayout.sections
          .filter(q => effectiveShowAll || q.answers)
          .map((section, idx) => (
            <Section
              key={idx}
              dataLayout={section}
              model={model}
              showAll={showAll}
            />
          ))
      ) : (
        <p className="text-yellow">{l('COMMON.NO_DATA_FOUND')}</p>
      )}
    </Card>
  );
};

Page.propTypes = {
  mode: PropTypes.oneOf(['read', 'edit']),
  dataLayout: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  showAll: PropTypes.bool,
  showInBox: PropTypes.bool,
};

Page.defaultProps = {
  showAll: true,
  showInBox: true,
};

export default Page;
