import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router'; // react-router v4
import { connect } from 'react-redux';

import routes from './routes';
import Loader from './components/Loader';

class MainRouter extends React.Component {
  componentWillMount() {
    this.unlisten = this.props.history.listen(location => {
      this.setState({ location });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { ready } = this.props;
    if (!ready) return <Loader />;

    const routeComponents = routes(this.props.authorized).map((r, idx) => {
      if (r.authorized !== undefined && !r.authorized) {
        return (
          <Route
            key={idx}
            {...r}
            component={null}
            render={() => <Redirect to="/login" />}
          />
        );
      }
      return <Route key={idx} {...r} />;
    });

    return (
      <div style={{ height: '100%' }}>
        <Switch>{routeComponents}</Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authorized: !!state.user.email,
  ready: state.ready,
});

export default withRouter(connect(mapStateToProps)(MainRouter));
