import React from 'react';
import PropTypes from 'prop-types';

import l from '../../libs/localization';
import { formatDateTime } from '../../libs/helpers';

const parsePath = path => {
  if (!path) return '-';
  const pos = path.lastIndexOf('/');
  return path.substring(pos + 1);
};

const formatType = type => (type === 'match' ? 'ean/serial' : type);

function FileImportRow(props) {
  const { r } = props;
  console.log('r', r);
  return (
    <tr key={r.id} title={r.statusMessage}>
      <td>{formatType(r.type)}</td>
      <td>
        <a
          href={'https://app.airtube-compressor.com' + r.downloadUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {parsePath(r.path)}
        </a>
        {/* <a
          href={
            'https://ac-aap-d-appl-airtube.azurewebsites.net' + r.downloadUrl
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          {parsePath(r.path)}
        </a> */}
      </td>
      <td>{r.status}</td>
      <td>{formatDateTime(r.createdAt)}</td>
      <td>{formatDateTime(r.processedAt)}</td>
      {/*<td>{l(new Date(r.createdAt), 'MMMM Do YYYY, HH:mm')}</td>
      <td>{l(new Date(r.processedAt), 'MMMM Do YYYY, HH:mm')}</td>*/}
    </tr>
  );
}
FileImportRow.propTypes = {
  r: PropTypes.shape({
    id: PropTypes.string,
    statusMessage: PropTypes.string,
    type: PropTypes.string,
    processedAt: PropTypes.string,
    createdAt: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

FileImportRow.defaultProps = {};

export default FileImportRow;
