import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';

export class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
  }

  state = { value: [] };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //console.log('shouldComponentUpdate', nextProps.value !== nextState.value,  nextProps.value, nextState.value);
    return (
      nextProps.value !== nextState.value || nextProps.data !== nextState.data
    );
  }

  onValueChange(event) {
    const { value, onChange, data } = this.props;

    if (onChange) {
      const selectedValue = event.target.name;

      let newValue = value || [];
      if (event.target.checked) {
        if (this.state.hasChilds) {
          const group = data.find(
            g =>
              g.key === selectedValue ||
              g.childs.find(c => c.key === selectedValue)
          );

          if (group.key === selectedValue) {
            newValue = newValue.filter(
              v => !group.childs.find(c => c.key === v)
            );
          } else {
            newValue = newValue.filter(v => v !== group.key);
          }
        }

        newValue = [...newValue, selectedValue];
      } else {
        newValue = value.filter(e => e !== selectedValue);
      }
      onChange(newValue);
    }
  }

  render() {
    const {
      value,
      onChange,
      className,
      defaultValue,
      data,
      title,
    } = this.props;

    // this optimize rendering, use purecomponent and refactor Form
    if (value !== this.state.value || data != this.state.data) {
      const hasChilds = !!(data && data.length && data[0].childs);

      setImmediate(() => this.setState({ value, data, hasChilds }));
    }

    const actualValue = value || [];

    const selectedValues = {};
    actualValue.forEach(v => (selectedValues[v] = true));

    const childs = [];

    const selectedClassName = actualValue.length > 0 ? 'withCheckboxes' : '';

    if (data) {
      data.forEach((f, idx) => {
        const key = f.key || f.label;
        const id = `${f.key}-${f.label}-${idx}-${title}`;
        const checkBoxName = key;

        childs.push(
          <li key={key}>
            <input
              id={id}
              name={checkBoxName}
              type="checkbox"
              onChange={this.onValueChange}
              checked={selectedValues[checkBoxName] || false}
            />
            <label htmlFor={id} title={f.label}>
              <span> {f.label}</span>
            </label>
            <span className="pull-right badge bg-gray">{f.count}</span>
          </li>
        );

        if (f.childs) {
          f.childs.forEach((c, cidx) => {
            const childKey = `${key}-${c.key || c.label}`;
            const id = `${key}-${c.key}-${c.label}-${cidx}-${title}`;
            const checkBoxName = c.key || c.label;

            childs.push(
              <li key={childKey} className="child-option">
                <input
                  id={id}
                  name={checkBoxName}
                  type="checkbox"
                  onChange={this.onValueChange}
                  checked={selectedValues[checkBoxName] || false}
                />
                <label htmlFor={id} title={c.label}>
                  <span> {c.label}</span>
                </label>
                <span className="badge bg-gray">{c.count}</span>
              </li>
            );
          });
        }
      });
    }

    return (
      <div className={`box box-widget multiselect ${selectedClassName}`}>
        <h4>{title}</h4>
        <div className="box-footer no-padding">
          {data == null ? (
            <Loader full={false} />
          ) : (
            <ul className="nav nav-stacked">{childs}</ul>
          )}
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChangeValue: PropTypes.func,
  className: PropTypes.string,
  glyph: PropTypes.string,
};

MultiSelect.defaultProps = {
  value: '',
  defaultValue: '',
  onChange: null,
  className: null,
  glyph: null,
};

export default MultiSelect;
