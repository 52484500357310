import userReducer from './user.reducers';
import compressorsReducer from './compressors.reducers';
import dealersReducer from './dealers.reducers';
import compressorDetailReducer from './compressorDetail.reducers';
import generalReducer from './general.reducers';
import notificationsReducers from './notifications.reducers';
import filesImportReducers from './files.import.reducers';
import usersReducers from './users.management.reducers';
import privacyReducers from './privacy.reducers';
import dealerDetailsReducer from './dealerDetail.reducers';
import shopDetailsReducer from './shopDetail.reducers';
import shopsReducerfrom from './shops.reducers';
import versionReducers from './version.reducers';

const initialState = {
  ready: true,
  user: null,
  compressors: null,
  compressor: null,
  dealers: null,
  dealer: null,
  notifications: null,
  filesImports: null,
};

const rootReducer = (state = initialState, action) => {
  let newState = {
    ...state,
    user: userReducer(state.user, action, state),
    compressors: compressorsReducer(state.compressors, action, state),
    compressor: compressorDetailReducer(state.compressor, action, state),
    dealers: dealersReducer(state.dealers, action, state),
    dealer: dealerDetailsReducer(state.dealer, action, state),
    shop: shopDetailsReducer(state.shop, action, state),
    shops: shopsReducerfrom(state.shops, action, state),
    notifications: notificationsReducers(state.notifications, action),
    filesImports: filesImportReducers(state.filesImports, action),
    users: usersReducers(state.users, action),
    privacy: privacyReducers(state.privacy, action),
    version: versionReducers(state.version, action),
  };
  newState = generalReducer(newState, action);
  return newState;
};

export default rootReducer;
