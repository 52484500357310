import { http } from './commons.graphql.api';
import { getConfig } from '../config';

// eslint-disable-next-line  import/prefer-default-export
export const listNotificationsApi = async params => {
  let tmp;
  // if (moment(params.dateTo).diff(params.dateFrom) === 0 && params.dateTo) {
  if (params.dateTo) {
    tmp = params.dateTo;
    params.dateTo = new Date(params.dateTo).setHours(23);
    params.dateTo = new Date(params.dateTo).setMinutes(59);
  }
  const response = await http.post('/admin/graphql', {
    query: `
    query q ($token: String! $dateTo: Timestamp $dateFrom: Timestamp $language: Language $id: String $status: NotificationStatus) {
        root(token: $token ) {
          notifications (dateTo: $dateTo dateFrom: $dateFrom language: $language id: $id status: $status)  {
            id plannedSendAt sentAt language
            text  status  statusMessage  createdAt
          }
        }
      }      
  `,
    variables: JSON.stringify({ token: getConfig('token'), ...params }),
  });
  params.dateTo = tmp || params.dateTo;
  return { data: response.data.root.notifications, filters: params };
};
export const createNotificationsApi = async params => {
  const response = await http.post('/admin/graphql', {
    query: `
    mutation addNotification ($token: String! $plannedSendAt: Timestamp! $text: String! $language: Language ) {
        prepareNotification(
          token:$token
          text: $text
          plannedSendAt: $plannedSendAt
          language: $language
        ) { id plannedSendAt sentAt language
            text  status  statusMessage  createdAt}
     }
    `,
    variables: JSON.stringify({
      token: getConfig('token'),
      ...params,
    }),
  });
  return {
    ...(response.data && response.data.prepareNotification),
    errors: response.errors,
  };
};

export const deleteNotificationsApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
      mutation deleteNotification($token: String! $id: String!) {
        deleteNotification(
            token:$token
            id: $id
          )
       }
      `,
      variables: JSON.stringify({ token: getConfig('token'), id: params.id }),
    })
    .then(() => listNotificationsApi(params.filters));
  return response;
};
export const changeNotificationStatusApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
      mutation changeStateNotification(
        $token: String!
        $id: String!
        $status: NotificationStatus!
        ) {
          changeStateNotification(
            token: $token
            id: $id
            status: $status
            ){ id plannedSendAt sentAt language
            text  status  statusMessage  createdAt}
       }
      `,
      variables: JSON.stringify({ token: getConfig('token'), ...params.data }),
    })
    .then(() => listNotificationsApi(params.filters));
  return response;
};
export const editNotificationApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation addNotification ($token: String! $plannedSendAt: Timestamp $text: String! $id: String! $language: Language!) {
        prepareNotification(
          id: $id
          token:$token
          text: $text
          plannedSendAt: $plannedSendAt
          language: $language
        ) { id plannedSendAt sentAt language
            text  status  statusMessage  createdAt}
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params.data,
      }),
    })
    .then(() => {
      return listNotificationsApi(params.filters);
    });
  return response;
};
