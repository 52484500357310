import { http } from './commons.api';
import { getConfig } from '../config';

// import log from '../libs/logger';

export const listPrivacyApi = async () => {
  const response = await http.post('/admin/graphql', {
    query: `
      query q ($token: String!) {
        root(token: $token ) {
          privacies {
            version
            url
            createdAt
            validFrom
            userCount
          }
        }
      }      
    `,
    variables: JSON.stringify({ token: getConfig('token') }),
  });
  response.data.root.privacies = response.data.root.privacies.sort(
    function sorter(a, b) {
      a = new Date(a.createdAt);
      b = new Date(b.createdAt);
      return a > b ? -1 : a < b ? 1 : 0;
    }
  );
  return response.data.root.privacies;
};
export const activatePrivacyApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! ) {
        activatePrivacyPolicy(
          token: $token
          version: $version
        ) {             
          version
          url
          createdAt
          validFrom
          userCount}
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params,
      }),
    })
    .then(() => {
      return listPrivacyApi();
    });
  return response;
};
export const deletePrivacyApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! ) {
      deletePrivacyPolicy(
          token: $token
          version: $version
        )
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        version: params.version,
      }),
    })
    .then(() => {
      return listPrivacyApi();
    });
  return response;
};

export const privacyCreateApi = async params => {
  const response = await http
    .post('/admin/graphql', {
      query: `
    mutation activate($token: String! $version: String! $url: String! ) {
      addPrivacyPolicy(
          token: $token
          version: $version
          url: $url
        ){
          url
        }
     }
    `,
      variables: JSON.stringify({
        token: getConfig('token'),
        ...params,
      }),
    })
    .then(() => {
      return listPrivacyApi();
    });
  return response;
};
