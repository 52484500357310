import { callApi } from './commons.actions';
import {
  listPrivacyApi,
  activatePrivacyApi,
  deletePrivacyApi,
  privacyCreateApi,
} from '../api';

export const PRIVACY_LIST = 'PRIVACY_LIST';
export const PRIVACY_LIST_COMPLETE = 'PRIVACY_LIST_COMPLETE';
export const PRIVACY_LIST_ERROR = 'PRIVACY_LIST_ERROR';

export const PRIVACY_ERROR = 'PRIVACY_ERROR';

export const privacyListLoadAction = callApi({
  apiCall: listPrivacyApi,
  startAction: PRIVACY_LIST,
  successAction: PRIVACY_LIST_COMPLETE,
  errorAction: PRIVACY_LIST_ERROR,
});

export const privacyActivateAction = callApi({
  apiCall: (p = {}) => {
    return activatePrivacyApi(p);
  },
  startAction: PRIVACY_LIST,
  successAction: PRIVACY_LIST_COMPLETE,
  errorAction: PRIVACY_LIST_ERROR,
});

export const privacyDeleteAction = callApi({
  apiCall: (p = {}) => {
    return deletePrivacyApi(p);
  },
  startAction: PRIVACY_LIST,
  successAction: PRIVACY_LIST_COMPLETE,
  errorAction: PRIVACY_LIST_ERROR,
});

export const privacyErrorAction = e => ({
  type: PRIVACY_ERROR,
  error: e,
});

export const privacyCreateAction = callApi({
  apiCall: (p = {}) => {
    return privacyCreateApi(p);
  },
  startAction: PRIVACY_LIST,
  successAction: PRIVACY_LIST_COMPLETE,
  errorAction: PRIVACY_LIST_ERROR,
});
