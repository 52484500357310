/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  usersListAction,
  userCreateAction,
  userDeleteAction,
  usersSearchAction,
  userEditAction,
  usersChangePageAction,
} from '../actions';
import l from '../libs/localization';
import Layout from '../layouts/Layout';
import TableCard from '../components/TableCard';
import Loader from '../components/Loader';
import UsersMenu from '../components/users/UsersMenu';
import User from '../components/users/User';
import CreateUser from '../components/users/CreateUser';
const UserMgmtPage = ({
  data,
  page,
  changePage,
  loading,
  message,
  filters,
  userList,
  userSetFilter,
  userCreate,
  userDelete,
  userEdit,
}) => {
  useEffect(() => {
    userList(filters);
  }, [filters, userList]);

  let rows;
  const pagination = [];
  const finaldata = data;

  if (data && data.length && page) {
    // Prepare Pagination
    const elements = data.length;
    const pageSize = 15;
    const pagesInPaginator = 7;
    const pages = Math.ceil(elements / pageSize);
    const index = (page - 1) * pageSize;
    const paginatorIndex = page - ((page - 1) % pagesInPaginator);
    data = data.slice(index, index + pageSize);
    if (pages > 1) {
      // Create Paginator
      // previous
      pagination.push(
        <li key="prev">
          <a
            onClick={() => (page - 1 !== 0 ? changePage(page - 1) : '')}
            className={page - 1 === 0 ? 'disabled' : ''}
          >
            {l('COMMON.PREVIOUS')}
          </a>
        </li>
      );
      // pages
      for (
        let i = paginatorIndex;
        i < pagesInPaginator + paginatorIndex && i <= pages;
        i++
      ) {
        pagination.push(
          <li key={i}>
            <a
              className={i === page ? 'disabled' : ''}
              onClick={() => changePage(i)}
            >
              {i}
            </a>
          </li>
        );
      }
      // dots if pages are hidden
      if (paginatorIndex + pagesInPaginator < pages + 1) {
        pagination.push(
          <li key="...">
            <a className="disabled">....</a>
          </li>
        );
      }
      // next
      pagination.push(
        <li key="next">
          <a
            className={page + 1 > pages ? 'disabled' : ''}
            onClick={() => (page + 1 <= pages ? changePage(page + 1) : '')}
          >
            {l('COMMON.NEXT')}
          </a>
        </li>
      );
    }
    var UserCompressorInfo = [];
    var CompressorDATA = [];
    var defaultPartnumber = '';
    var brand = [];

    UserCompressorInfo = JSON.parse(localStorage.getItem('UserCompressorInfo'));
    CompressorDATA = JSON.parse(localStorage.getItem('CompressorDATA'));

    rows = data.map(
      r => (
        UserCompressorInfo.map(uc => {
          //if (filters.type === 'user') {
          if (r.email == uc.userId) {
            defaultPartnumber = uc.defaultCompressor;
          }
          // }
        }),
        CompressorDATA.map(c => {
          if (defaultPartnumber === c._id) {
            brand = c.data.brand;
          }
        }),
        (
          <User
            key={r.email}
            brand={brand}
            defaultPartnumber={defaultPartnumber}
            r={r}
            userDelete={userDelete}
            onSubmit={userEdit}
            filters={filters}
          />
        )
      )
    );
  } else {
    rows = (
      <tr colSpan="5">
        <td>{l('COMMON.NO_DATA_FOUND')}</td>
      </tr>
    );
  }

  return (
    <Layout title={l('USERS.PAGE_TITLE')}>
      <div className="users-container">
        <TableCard
          header={
            <div className="user-header">
              <UsersMenu
                filters={filters}
                onFilter={userSetFilter}
                data={finaldata}
              />
              <CreateUser
                onCreate={userCreate}
                filters={filters}
                data={finaldata}
              />
              {message && <p>{message}</p>}
            </div>
          }
          footer={<div className="pagination">{pagination}</div>}
        >
          <tbody>
            <tr className="table-header">
              <th />
              <th>{l('USERS.EMAIL')}</th>
              <th>{l('USERS.FIRSTNAME')}</th>
              <th>{l('USERS.LASTNAME')}</th>
              {/* <th>{l('USERS.NAME')}</th> */}
              <th>{l('USERS.TYPE')}</th>
              <th>{l('Language')}</th>
              <th>{l('USERS.COUNTRY')}</th>
              <th>Brand</th>
              <th>Part Number</th>
              <th>{l('USERS.LAST_ACCESS')}</th>
              <th />
            </tr>

            {rows}
          </tbody>
        </TableCard>
      </div>
      <Loader visible={loading} />
    </Layout>
  );
};

UserMgmtPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  loading: PropTypes.bool,
  message: PropTypes.string,
  filters: PropTypes.shape({
    active: PropTypes.bool,
    search: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  userList: PropTypes.func.isRequired,
  userSetFilter: PropTypes.func.isRequired,
  userCreate: PropTypes.func.isRequired,
  userDelete: PropTypes.func.isRequired,
  userEdit: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

UserMgmtPage.defaultProps = {
  data: [],
  loading: true,
  message: null,
  page: 1,
};

const mapStateToProps = state => ({
  ...state.users,
});

const mapDispatchToProps = {
  userList: usersListAction,
  userSetFilter: usersSearchAction,
  userCreate: userCreateAction,
  userDelete: userDeleteAction,
  userEdit: userEditAction,
  changePage: usersChangePageAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMgmtPage);
