import React from 'react';
import { Link } from 'react-router-dom';

import l from '../libs/localization';

import Layout from '../layouts/Layout';
import { compressorListAction, ListCompressorUserAction } from '../actions';
import { useState, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { connect } from 'react-redux';

// const HomePage = () => (
const HomePage = props => {
  const {
    UserCompressorList, //UserList,
    data,
    compressorList,
  } = props;
  useEffect(() => {
    //UserList();
    UserCompressorList();
    compressorList();
    //UserList();
  }, []);
  return (
    <Layout>
      <div className="homepage">
        <div className="row">
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            <Link to="/compressors">{l('MENU.COMPRESSOR')}</Link>
          </div>
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            <Link to="/imports">{l('MENU.IMPORT')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            <Link to="/notifications">{l('MENU.NOTIFICATIONS')}</Link>
          </div>
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            <Link to="/privacy">{l('MENU.PRIVACY')}</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            <Link to="/users">{l('MENU.USER')}</Link>
          </div>
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            {/* <div className="spacer" /> */}
            <Link to="/dealers">{l('MENU.DEALER')}</Link>
          </div>
          <div className="col-xs-12 col-md-6 d-flex j-c-c">
            {/* <div className="spacer" /> */}
            <Link to="/shops">{l('MENU.SHOP')}</Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  //...state.campaign,
  //...state.users,
  ...state.userCompressor,
  ...state.compressors,
  //...state.users,
});

const mapDispatchToProps = {
  ///campaignList: ListCamapignAction,
  //CampaginFormCreate: CampaginFormAction,
  //CampaignDelete: CampaignDeleteAction,
  //CampaignEdit: CampaignEditAction,
  //UserList: usersListAction,
  UserCompressorList: ListCompressorUserAction,
  compressorList: compressorListAction,
  //UserList: usersListAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
